/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */


/**
 * 1) Block
 */

.melding {
  color: $color-primary;
  background-color: #ffeb3b;
  font-weight: $font-bold;
  text-align: center;
  font-family: $font-serif;
  z-index: 21;
  position: relative;

  @include respond-max(480px) {
    min-height: 75px
  }

  .menu-is-active & {
    display: none;
  }

  * {
    color: $color-primary;
  }

  .BW & {
    background-color: $color-white;
    color: $color-black;

    * {
      color: $color-black;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
