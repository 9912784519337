/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.procedure {
  background-image: url("/assets/dist/img/img-procedure.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .BW & {
    background-color: $color-white;

    .button {
      background-color: $color-white !important;
      color: $color-black !important;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: $font-serif-black;
    font-family: $font-serif;
    color: $color-white;
    margin-bottom: 40px;

    @include respond-max(768px) {
      font-size: 24px;
    }

    .BW & {
      color: $color-white;
    }
  }

  .procedure__item {

    .BW & {
      color: $color-black;
    }

    .content {
      @include respond-max(768px) {
        border-bottom: 1px dotted darken($color-bg, 10%);
        padding-bottom: 20px;
      }
    }

    .count {
      text-align: center;
      margin: 0 auto;
      width: 80px;
      margin-bottom: 40px;

      .inner {
        font-size: 41px;
        font-weight: $font-bold;
        color: $color-primary;
        width: 80px;
        height: 80px;
        line-height: 80px;
        border-radius: 100%;
        background-color: $color-white;

        .BW & {
          background-color: $color-white;
          color: $color-black;
        }
      }
    }

    .content {
      color: $color-white;

      .BW & {
        p {
          color: $color-white !important;
        }
      }

      h3 {
        color: $color-green;
        font-size: 18px;
        font-family: $font-serif;
        font-weight: $font-serif-black;
        margin-bottom: 0px;

        .BW & {
          color: $color-white;
        }
      }
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
