/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

.readspeaker {
  background-color: $color-white;
  position: absolute;
  top: -300px;
  display: block;
  transition: all .8s ease;
  overflow: hidden;
  right: 0;
  padding: 60px 8px 20px 8px;
  min-width: 250px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
  min-width: 100%;
  z-index: 100;

  @include respond-min($sm) {
    min-width: 250px;
    right: 15px;
    padding: 20px 50px 10px 20px;
  }

  .fa-times {
    right: 20px;
    position: absolute;
    top: 20px;
    z-index: 100;
    font-size: 24px;
    cursor: pointer;

    .BW & {
      background: none !important;
    }

    @include respond-min($md) {
      &:hover {
        color: $color-secondary;
      }
    }
  }

  &.active {
    display: none !important;

    @include respond-min($md) {
      top: -20px !important;

      .header--small & {
        padding-bottom: 8px;
        top: -4px !important;
      }
    }
    top: -5px;
    transition: all .8s ease;
  }
}

/**
 * 1) Block
 */

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
