/**
 * Utilitites/list stylesheet
 */

.u-list-unset,
%u-list-unset {
    list-style: none !important;
}

.u-list-numbers,
%u-list-numbers {
    list-style: decimal !important;
}

.u-list-bullets,
%u-list-bullets {
    list-style: disc !important;
}

.u-list-inline,
%u-list-inline {
    & li {
        display: inline-block !important;
    }
}

.u-list-block,
%u-list-block {
    & li {
        display: block !important;
    }
}

@each $breakpoint in $breakpoints {
    $key: nth($breakpoint, 1);
    $value: nth($breakpoint, 2);

    .u-list-unset--#{$value},
    %u-list-unset--#{$value} {
        @include respond-min(#{$key}) {
            list-style: none !important;
        }
    }

    .u-list-numbers--#{$value},
    %u-list-numbers--#{$value} {
        @include respond-min(#{$key}) {
            list-style: decimal !important;
        }
    }

    .u-list-bullets--#{$value},
    %u-list-bullets--#{$value} {
        @include respond-min(#{$key}) {
            list-style: disc !important;
        }
    }

    .u-list-inline--#{$value},
    %u-list-inline--#{$value} {
        @include respond-min(#{$key}) {
            li {
                display: inline-block !important;
            }
        }
    }

    .u-list-block--#{$value},
    %u-list-block--#{$value} {
        @include respond-min(#{$key}) {
            li {
                display: block !important;
            }
        }
    }
}
