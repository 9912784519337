/**
 * Webshop/filters stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */



/**
 * 1) Block
 */

.filters {}



.active-filters {}



/**
 * 2) Element
 */

.filters__item {
    display: none;
}



.filters__label {
    display: block;
}



.active-filters__item {
    background-color: darken($color-bg, 2%);
    border: 1px solid darken($color-bg, 5%);
    cursor: pointer;
}



.filters__search {
    position: relative;
}



.filters__search__input {
    box-sizing: border-box !important;
    width: 100%;
}



.filters__search__clear {
    display: none;
}



.filters__search__submit {}



.filters__search__clear,
.filters__search__submit {
    background: none;

    border: none;

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}



/**
 * 3) Modifier
 */
