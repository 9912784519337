/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */
textarea{
  pointer-events: auto !important;
}

* {
  outline: none;
}

input.text.error, input.text.error, .textarea.error, .error {
  background: #ff9c9c !important;

  &::-webkit-input-placeholder {
    color: $color-white !important;
  }
  &:-moz-placeholder {
    color: $color-white !important;
  }
  &::-moz-placeholder {
    color: $color-white !important;
  }
  &:-ms-input-placeholder {
    color: $color-white !important;
  }

  .BW & {
    background: $color-black !important;
    color: $color-white !important;
  }
}

button {
  background-color: $color-darkgreen;
}

.contact {
  input[type=text], textarea {
    background: $color-white;
  }
}

#error {
  position: relative;
  padding: 10px;
  border: 1px solid #ec8b8b;
  background: rgb(203, 82, 82);
  color: white;
  margin: 20px 0;

  .BW & {
    background: $color-black !important;
    color: $color-white !important;
    border-color: $color-black !important;
  }
}

#success {
  position: relative;
  border: 1px solid #41912b;
  background: rgba(65, 145, 43, 0.98);
  padding: 10px;
  color: $color-white;
  margin: 20px 0;

  .BW & {
    background: $color-black !important;
    color: $color-white !important;
    border-color: $color-black !important;
  }
}

#item.error, input.error {
  color: red;

  .BW & {
    color: $color-black;
    font-weight: $font-bold;
  }
}

textarea, textarea.form-control {
  background: $color-bg;
  -webkit-appearance: none;
  border-collapse: collapse;
  border: 0px;
  margin-bottom: 5px;
  width: 100% !important;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  font-size: 16px !important;
}

select, input[type="text"], input[type="password"], input[type="email"], input.text, input.form-control, input[type="number"] {
  font-size: 16px !important;
  height: 50px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  line-height: 50px;
  width: 100%;
  border-collapse: collapse;
  border: 0px;
  margin-bottom: 5px;
  padding: 0 10px;
  box-sizing: border-box;
  background: $color-bg;
}

*::-webkit-input-placeholder, textarea, textarea.form-control, *::-webkit-input-textarea, -webkit-input-placeholder, input:-moz-placeholder {
  -webkit-text-security: none;
  color: darken($color-bg, 30%) !important;
  pointer-events: none;
}

textarea, textarea.form-control {
  height: 150px;
  width: 100%;
  padding: 10px;
}

::-webkit-input-placeholder {
  color: darken($color-bg, 30%) !important;
  opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
  color: darken($color-bg, 30%) !important;
  opacity: 1;
}

::-moz-placeholder { /* Firefox 19+ */
  color: darken($color-bg, 30%) !important;
  opacity: 1;
}

:-ms-input-placeholder, input[placeholder], [placeholder], *[placeholder] {
  opacity: 1;
  color: darken($color-bg, 30%) !important;
}

input[type="text"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, input[type="search"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input.text::-webkit-input-placeholder, input.keywords::-webkit-input-placeholder, input.form-control::-webkit-input-placeholder {
  color: darken($color-bg, 30%) !important;
}

/**
 * 1) Block
 */

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
