/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

.nav-breadcrumbs {
  background-color: $color-green30;
  text-align: right;
  overflow: hidden;
  display: block;
  width: 100%;
  padding: 15px 0;

  .BW & {
    background-color: $color-black;
    .breadcrumbs {
      p {
        color: $color-white;
      }
      .seperator {
        &:after {
          color: $color-white;
        }
      }
      a {
        color: $color-white;

      }
    }
  }

  &.line {
    background-color: $color-bg !important;
    border-bottom: 1px solid $color-bg-light;

    .BW & {
      a, p, .seperator:after {
        color: $color-black !important;
      }
    }
  }

  &.grey {
    background-color: $color-bg;

    .BW & {
      background-color: $color-bg-light !important;

      a, p, .seperator:after {
        color: $color-black !important;
      }
    }
  }

  &.blue {
    background-color: $color-blue30;

    .BW & {
      background-color: $color-bg;
      a, p, .seperator:after {
        color: $color-black !important;
      }
    }
  }

  &.white {
    background-color: $color-white;

    .BW & {
      background-color: $color-bg-light !important;

      a, p, .seperator:after {
        color: $color-black !important;
      }
    }
  }

  &.green {
    background-color: $color-darkgreen15;

    .BW & {
      background-color: $color-black !important;

      a, p, .seperator:after {
        color: $color-white !important;
      }
    }
  }

  &.darkgreen {
    background-color: #a1c39e;

    .BW & {
      background-color: $color-black !important;

      a, p, .seperator:after {
        color: $color-white !important;
      }
    }
  }

  &.yellow {
    background-color: $color-yellow;

    .BW & {
      background-color: $color-white !important;

      a, p, .seperator:after {
        color: $color-black !important;
      }
    }
  }

  &.yellows {
    background-color: $color-yellow30;

    .BW & {
      background-color: $color-black !important;

      a, p, .seperator:after {
        color: $color-white !important;
      }
    }
  }

  &.turquoise {
    background-color: $color-turquoise15;

    .BW & {
      background-color: $color-black !important;

      a, p, .seperator:after {
        color: $color-white !important;
      }
    }
  }

  &.turquoise50 {
    background-color: $color-turquoise50;

    .BW & {
      background-color: $color-black !important;

      a, p, .seperator:after {
        color: $color-white !important;
      }
    }
  }

  p {
    display: inline-block;
    margin-bottom: 0px;
    color: $color-primary;
    font-weight: $font-bold;
    font-size: 14px;
    margin-right: 5px;
  }

  .seperator {
    color: transparent !important;
    &:after {
      content: "\f105";
      font-size: 15px;
      color: $color-primary;
      font-family: "Font Awesome 6 Pro";
      position: relative;
      left: -7px;
      top: 1px;
    }
  }

  .breadcrumbs {
    a {
      display: inline-block;
      font-size: 14px;
      color: $color-primary;
      text-decoration: underline;

      @include respond-min($md) {
        &:hover {
          text-decoration: none;
        }
      }

      &:last-child {
        text-decoration: none;
        cursor: default !important;
      }
    }
  }
}

/**
 * 1) Block
 */

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
