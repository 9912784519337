/**
* Config/vars stylesheet
*
* Table of contents
* 1) Breakpoints
* 2) Colours
* 3) Typography
* 4) Layout
* 5) Z-index
* 6) Utility
* 7) CDN
*/

/**
* 1) Breakpoints
*/

$xs: 30em; // 480px --> $bp1
$sm: 48em; // 768px --> $bp2
$md: 62em; // 992px --> $bp3
$lg: 75em; // 1200px --> $bp4

$breakpoints: (
        $xs "xs",
        $sm "sm",
        $md "md",
        $lg "lg",
);

/**
* 2) Colours
*/

$color-text: #222222;
$color-bg: #E3E6E8;
$color-bg-light: #f1f3f4;
$color-link: #84BC00;

$color-white: #FFFFFF;
$color-black: #000000;

$color-primary: #425462;
$color-secondary: #84BC00;

$color-yellow: #CEDC00;
$color-yellow70: #DCE64C;
$color-yellow30: #F0F4B3;
$color-yellow15: #F7F9D9;
$color-green: #84BC00;
$color-green70: #A8D04C;
$color-green30: #DAEBB3;
$color-green15: #EFF6DF;
$color-darkgreen: #44873D;
$color-darkgreen70: #7BAA76;
$color-darkgreen30: #C7DBC5;
$color-darkgreen15: #E3EDE2;
$color-turquoise: #00A499;
$color-turquoise50: #7FD1CC;
$color-turquoise70: #4CBFB7;
$color-turquoise30: #B3E3E0;
$color-turquoise15: #D9F1EF;
$color-bluedark: #0092CA;
$color-blue: #00A3E1;
$color-blue70: #80d1f0;
$color-blue30: #B3E3F6;
$color-blue15: #D9F1FA;
$color-text-dark: #354550;
$color-text-dark70: #7A8791;
$color-text-dark30: #C6CCD0;
$color-held: #5066AE;

/**
* 3) Typography
*/

$font-sans: "Roboto", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-serif: "filson-soft", "Roboto";

$font-bold: 700;
$font-regular: 400;
$font-light: 400;

$font-serif-regular: 300;
$font-serif-bold: 700;
$font-serif-black: 800;
$font-serif-heavy: 900;

/**
* 4) Layout
*/

$baseline: 20px;

/**
* 5) Z-index
*/

$z-index-1: 1;
$z-index-2: 5;
$z-index-3: 10;
$z-index-4: 15;
$z-index-5: 20;

/**
* 6) Utility
*/

$spacing-sizes: (
        'xxs': .25,
        'xs': .5,
        's': 1,
        'one': 1,
        'm': 1.5,
        'l': 2,
        'two': 2,
        'three': 3,
        'xl': 4,
        'four': 4,
        'five': 5,
        'xxl': 6,
        'jumbo': 8,
        'none': 0,
);

$spacing-edges: (
        'top',
        'right',
        'bottom',
        'left',
        'vertical' ('top' 'bottom'),
        'horizontal' ('left' 'right'),
);

/**
* 7) CDN
*/

$cdn: 'https://placehold.it/';
