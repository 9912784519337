/**
 * Modules/media stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */



/**
 * 1) Block
 */

.js-img, .section-bottom-image, .section-square-image, .hero__img, .boxes__image, .navigation, .questions, .hero__item, .hero__img-small, .section-list-image .image__item, .section-list-image .video__item, h2.toggle, .locations .image, .locations img, .footer .image__footer, .footer img {
    display: block;

    .no-jquery & {
        display: none;
    }
}



/**
 * 2) Element
 */



/**
 * 3) Modifier
 */
