/**
 * Utilities/visibility stylesheet
 */

.u-visible,
%u-visible {
    display: block !important;
}

.u-hidden,
%u-hidden {
    display: none !important;
}

.u-jquery,
%u-jquery {
    display: none !important;
}

@each $breakpoint in $breakpoints {
    $key: nth($breakpoint, 1);
    $value: nth($breakpoint, 2);

    .u-visible--#{$value},
    %u-visible--#{$value} {
        display: none !important;

        @include respond-min(#{$key}) {
          display: block !important;
        }
    }

    .u-hidden--#{$value},
    %u-hidden--#{$value} {
        display: block !important;

        @include respond-min(#{$key}) {
          display: none !important;
        }
    }
}
