/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.boxes {

  .BW & {
    h2, p {
      color: $color-black !important;
    }
  }

  .boxes__item {
    @include respond-max(991px) {
      margin-bottom: 30px;
      padding-bottom: 40px;
      border-bottom: 1px dotted $color-primary;

      &:last-child {
        border: 0;
      }

      .BW & {
        border-color: $color-black !important;
      }
    }

    h2 {
      font-family: $font-serif;
      font-weight: $font-serif-black;
      font-size: 32px;
      color: $color-primary;

      @include respond-max(991px) {
        font-size: 24px !important;
      }
    }

    p {
      font-size: 18px;
      color: $color-primary;
    }

    .wiezijnwij & {
      &:nth-child(1) {
        .boxes__image {
          &:before {
            background-color: rgba($color-blue, .6) !important;
          }
        }
      }
      &:nth-child(2) {
        .boxes__image {
          &:before {
            background-color: rgba($color-green, .6) !important;
          }
        }
      }
      &:nth-child(3) {
        .boxes__image {
          &:before {
            background-color: rgba($color-turquoise, .6) !important;
          }
        }
      }
    }

  }

  .boxes__image {
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;

    .wiezijnwij & {
      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 10px;
      }
    }

    .image {
      padding-bottom: 75%;
      display: block;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 10px;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
