/**
 * Webshop/webshop stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */



/**
 * 1) Block
 */

.webshop {
    margin-top: $baseline;
}



/**
 * 2) Element
 */



/**
 * 3) Modifier
 */
