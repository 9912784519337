/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.quote {
  .BW & {
    background-color: $color-white;
  }
  h3 {
    color: $color-primary;
    font-family: $font-serif;
    font-weight: $font-serif-black;
    font-size: 32px;

    .BW & {
      color: $color-black;
    }
  }
  h2 {
    color: $color-secondary;
    font-family: $font-serif;
    font-weight: $font-serif-black;
    font-size: 48px;

    @include respond-max($sm) {
      font-size: 26px;
    }

    .BW & {
      color: lighten($color-black, 30%);
    }
  }
  p {
    font-size: 18px;
    color: $color-primary;

    .BW & {
      color: $color-black;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
