/**
 * Layout/header stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */
.tabs {
  position: fixed;
  top: 310px;
  right: 0;
  z-index: 22;

  @include respond-between($md, 1199px) {
    top: 200px;
  }

  @include respond-max(991px) {
    bottom: 77px;
    top: auto;
  }

  .page & {
    @include respond-between($md, 1199px) {
      top: 220px;
    }
  }

  ul {
    li {
      background-color: rgba($color-secondary, .8);
      border-radius: 15px 0 0 15px;
      padding: 0 10px;
      margin-bottom: 10px;
      color: $color-white;
      height: 55px;
      line-height: 55px;
      transition: all .3s ease;
      right: calc(100% - 60px);
      width: 70px;
      overflow: hidden;
      float: right;
      clear: both;
      cursor: pointer;

      @include respond-max(991px){
        height: 35px;
        width: 55px;
        line-height: 35px;
        font-size: 20px;
      }

      .BW & {
        background-color: $color-black;
      }

      &.active {
        right: calc(100% - 70px);
        transition: all .3s ease;
        width: 320px;

        @include respond-max(991px){
          right: calc(100% - 55px);
        }

        a {
          transition: all .3s ease;
          opacity: 1;
        }
      }

      a {
        color: $color-white;
        text-decoration: none;
        font-family: $font-serif;
        display: inline-block;
        font-size: 24px;
        position: relative;
        top: 0px;
        height: 55px;
        line-height: 55px;
        width: calc(100% - 70px);
        opacity: 0;
        transition: all .3s ease;
        float: left;

        @include respond-max(991px){
          height: 35px;
          width: 55px;
          line-height: 35px;
          font-size: 20px;
        }

        @include respond-min($md) {
          &:hover {
            color: $color-white;
            text-decoration: underline;
          }
        }
      }

      &:nth-child(2) {
        background-color: rgba($color-secondary, .8);

        .BW & {
          background-color: $color-black;
        }
      }

      &:nth-child(3) {
        background-color: rgba($color-primary, .8);

        .BW & {
          background-color: $color-black;
        }
      }

      i {
        font-size: 32px;
        width: 55px;
        height: 55px;
        line-height: 55px;
        margin-right: 10px;
        text-align: center;
        color: $color-white;
        position: relative;
        display: inline-block;
        float: left;

        @include respond-max(991px){
          width: 35px;
          height: 35px;
          line-height: 35px;
          font-size: 22px;
        }

      }
    }
  }
}

.kaarstab {
  position: fixed;
  top: 310px;
  right: 0;
  z-index: 10;
  transition: all .3s ease;

  ul {

    li {
      background-color: rgba($color-secondary, .8);
      border-radius: 15px 0 0 15px;
      padding: 0 10px;
      margin-bottom: 10px;
      color: $color-white;
      height: 55px;
      line-height: 55px;
      transition: all .3s ease;
      right: calc(100% - 60px);
      width: 70px;
      overflow: hidden;
      float: right;
      clear: both;
      cursor: pointer;

      .BW & {
        background-color: $color-black;
      }

      &.active {
        right: calc(100% - 70px);
        transition: all .3s ease;
        width: 320px;

        a {
          transition: all .3s ease;
          opacity: 1;
        }
      }

      a {
        color: $color-white;
        text-decoration: none;
        font-family: $font-serif;
        display: inline-block;
        font-size: 24px;
        position: relative;
        top: 0px;
        height: 55px;
        line-height: 55px;
        width: calc(100% - 70px);
        opacity: 0;
        transition: all .3s ease;
        float: left;
      }

      &.kaarsje {
        background-color: rgba($color-primary, 1);
        position: fixed;
        bottom: 10px;
        right: 10px;
        border-radius: 15px;

        a {
          left: 10px;

          @include respond-min($md) {
            &:hover {
              color: $color-secondary;
              text-decoration: none;

              .BW & {
                background-color: $color-white;
              }
            }
          }
        }

        .BW & {
          background-color: $color-black;
        }
      }

      i {
        font-size: 32px;
        width: 55px;
        height: 55px;
        line-height: 55px;
        margin-right: 10px;
        text-align: center;
        color: $color-white;
        position: relative;
        display: inline-block;
        float: left;
      }
    }
  }
}

.menu-is-active {
  @include respond-between($sm, $md) {
    .header {
      height: 600px !important;
      background: none !important;
    }
  }
  @include respond-max(767px) {
    .header {
      height: 600px !important;
      background-color: $color-green !important;
    }
    &.BW {
      .header {
        background: $color-black !important;
      }
    }
  }
}

.header:not(.header--small) {
  .has-notification:not(.grey):not(.home) & {
    top: 45px;

    @include respond-between($xs, 991px) {
      top: 60px;
    }

    @include respond-max(480px) {
      top: 85px;
    }
  }
}

.header {
  width: 100%;
  height: 600px;
  background-color: none;
  position: absolute;
  top: 0;
  z-index: $z-index-5;
  transition: all .2s ease;
  display: block;


  .BW & {
    .contact {
      .inner {
        a {
          color: $color-white;
        }

        color: $color-white !important;
        background-color: $color-black !important;

        .content {
          span {
            color: $color-white !important;
          }
        }
      }
    }
  }

  .colors & {
    height: 210px !important;
    @include respond-between($sm, $md) {
      height: 80px !important;
    }
    @include respond-max($sm) {
      height: auto !important;
    }
    @include respond-max(767px) {
      .options {
        top: 135px !important;
      }
    }
  }

  .page & {
    height: 700px;

    @include respond-between($sm, $md) {
      height: 600px !important;
    }

    @include respond-min(993px) {
      height: 170px !important;
    }
    @include respond-max($sm) {
      height: auto !important;
    }
    @include respond-max(767px) {
      height: auto !important;

      .options {
        top: 135px !important;
      }
    }
  }

  &.no-headerimage {
    background-color: $color-bg;
    height: 170px !important;

    .header--small {
      background-color: $color-white;
    }

    .contrast, .listen {
      text-shadow: none !important;

      .fa {
        color: $color-primary;

        .BW & {
          color: $color-white;
        }
      }

      .nav__link {
        color: $color-secondary;

        .BW & {
          color: $color-white;
        }
      }
    }

    .nav__secondary {
      .nav__item {
        .nav__link {
          text-shadow: none !important;
          color: $color-primary;
        }
      }
    }
  }

  .logo__small {
    @include respond-min($md) {
      display: none;
    }
  }

  &.header--small {
    height: 60px !important;
    background-color: $color-white;
    position: fixed;
    padding: 0;
    transition: all .2s ease;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, .5);

    .BW & {
      .nav__primary {
        background: $color-white !important;

        > .nav__item {
          > .nav__link {
            color: $color-black !important;

            &:after {
              border-color: $color-black !important;
            }
          }

          &.has-sub {
            .nav__sub {
              background-color: $color-black !important;
              border: solid $color-white 1px;
              border-top: 0px;

              .nav__item {

                &.selected, &:hover {
                  .nav__link {
                    color: $color-white !important;
                    background: none !important;
                  }
                }

                .nav__link {
                  color: $color-white !important;
                }
              }

              &:before {
                background: $color-white;
              }
            }
          }

          &:hover {
            > .nav__link {
              background-color: $color-black !important;
              color: $color-white !important;

              &:after {
                display: none;
              }
            }

            &:first-child {
              > .nav__link {
                border-left: 1px solid white;
              }
            }

            &:last-child {
              > .nav__link {
                border-right: 1px solid white;
              }
            }

            .sub-toggle {
              background: none !important;
            }

          }

        }

        .nav__sub {
          &:before {
            background: $color-white !important;
          }
        }

      }

      .nav__secondary {
        display: none;
      }
    }

    .logobg {
      display: none;
    }

    .contact {
      display: none;
    }

    .logo__wrapper {
      display: none !important;
    }

    .logo__small {
      display: block !important;

      .logo-left {
        display: block;
      }

      .logo-left-zw {
        display: none;
      }

      .logo {
        width: 170px;
        height: 40px;
        margin-top: 9px;
      }

    }

    .navigation {
      float: right;
      transition: all .2s ease;

      @include respond-min($md) {
        .green {
          position: relative !important;
          top: 0 !important;
          left: 0;
          max-width: 100% !important;

          .nav__primary {
            margin-top: -1px;
            padding: 0px;
            background-color: $color-white;
            box-shadow: none !important;
            border-radius: 0px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            position: relative;
            transition: all .2s ease;

            display: flex;
            flex-wrap: wrap;

            > .nav__item {
              float: left !important;
              width: auto !important;
              position: static;
              height: 60px;
              flex-grow: 1;

              &:last-child {
                > .nav__link {
                  &:after {
                    display: none;
                  }
                }
              }

              > .nav__link {
                font-size: 20px !important;
                border-radius: 0px !important;
                color: $color-green;
                height: 60px;
                line-height: 60px;
                padding: 0 15px;
                width: 100% !important;

                @include respond-between($md, 1200px) {
                  font-size: 18px !important;
                  padding: 0 5px;

                  &:before {
                    margin-left: 0px !important;
                    font-size: 16px !important;
                  }

                }

                &:after {
                  border-bottom: 0px;
                  content: "";
                  height: 30px;
                  border-right: 1px dotted $color-green;
                  position: absolute;
                  top: 25%;
                  right: -1px;
                }
              }

              &.has-sub {
                height: 60px;

                > .nav__link {
                  &:before {
                    content: "\f0d7";
                    font-family: "Font Awesome 6 Pro";
                    display: inline-block;
                    border-top: 0px;
                    color: #425462;
                    line-height: 60px;
                    font-size: 20px;
                    margin-left: 5px;
                    float: right;
                    right: 0px;
                    width: 12px;
                    position: relative;
                    padding: 0;
                    text-align: right !important;
                    z-index: 6;

                    .BW & {
                      color: $color-black;
                    }
                  }
                }

                &:hover {
                  background-color: $color-secondary;

                  > .nav__link {
                    &:before {
                      .BW & {
                        color: $color-white;
                      }
                    }
                  }

                  .nav__sub {
                    display: block !important;
                  }
                }

                .nav__sub {
                  width: 100%;
                  column-count: 2;
                  z-index: 4;
                  border-radius: 0 0 15px 15px;
                  padding: 30px 45px;
                  box-shadow: 0 1px 10px rgba($color-black, .1);
                  background-color: $color-green;

                  .nav__item {
                    width: 100%;
                    height: 30px !important;
                    line-height: 30px !important;
                    float: left;

                    &.selected, &:hover {
                      .nav__link {
                        color: $color-primary;
                        text-decoration: none;
                      }
                    }

                    .nav__link {
                      width: 100%;
                      color: $color-white;
                      font-size: 18px;
                      height: 30px !important;
                    }
                  }

                  &:before {
                    content: "";
                    width: 100%;
                    height: 10px;
                    background: $color-green;
                    position: absolute;
                    top: -10px;
                    left: 0;
                  }
                }
              }

              &:hover {
                > .nav__link {
                  background-color: $color-green;
                  color: $color-white;

                  &:after {
                    display: none;
                  }
                }

                &:last-child {
                  > .nav__link {
                    border-radius: 0;
                  }
                }

                &:first-child {
                  > .nav__link {
                    border-radius: 0;
                  }
                }
              }

            }

            .nav__sub {
              &:before {
                background: $color-white !important;
              }
            }

          }
        }
      }

      .nav__primary {
        transition: all .2s ease;
      }

      .nav__secondary {
        display: none;
      }
    }

    .logo {

    }
  }

  .contact {
    @include respond-max(767px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @include respond-max(768px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .inner {
      border-radius: 15px;
      padding: 10px 10px 0 10px;
      border: 1px solid $color-white;
      background: rgba($color-black, .3);
      height: 130px;
      max-width: 343px;

      @include respond-max($sm) {
        margin-top: 365px;
      }
      @include respond-between($xs, $sm) {
        margin-top: 375px;
      }
      @include respond-between($sm, 991px) {
        margin-top: 430px;
      }

      .item {

        &:first-child {
          .content {
            border-bottom: 1px dotted $color-white;
            padding-bottom: 8px;
            margin-bottom: 2px;
          }
        }

        .content {
          width: 80%;
          display: inline-block;
          float: right;

          @include respond-between($md, $lg) {
            width: 80% !important;
            a {
              font-size: 16px;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          a {
            font-size: 20px;
            text-decoration: none;
            font-family: $font-serif;
            color: $color-white;
            font-weight: $font-serif-black;

            @include respond-min($md) {
              &:hover {
                text-decoration: underline;
              }
            }

          }

          span {
            clear: both;
            display: block;
            font-size: 14px;
            color: $color-green;
            margin-top: -3px;
          }
        }

        .icon {
          height: 50px;
          width: 35px;
          line-height: 50px;
          font-size: 22px;
          text-align: center;
          display: inline-block;
          float: left;

          .fa {
            color: $color-white;
          }
        }
      }
    }

  }

  @include respond-min($md) {
    height: 115px;
    background: none;
    padding: $baseline 0;
    position: absolute;
    width: 100%;
  }


  &.rounded {
    .nav .nav__primary {
      border-radius: 10px !important;
    }
  }

}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */