/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.interview {
  .BW & {
    background-color: $color-white;
  }

  .item {
    overflow: hidden;
    padding-left: 0px !important;
    padding-right: 0px !important;
    position: relative;
    cursor: pointer;

    @include respond-min($md) {
      &:hover {
        .interview__image {

          .button {
            transition: all .5s ease;
            bottom: calc(50% - 25px);
          }

          .info {
            transition: all .5s ease;
            bottom: -100%;
          }
        }
      }
    }

    @include respond-max(991px) {
      .button {
        transition: all .5s ease !important;
        bottom: calc(50%) !important;
      }
    }

    &:before {
      content: '';
      background-color: rgba($color-secondary, .7);
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
    }

    .info {
      background-color: $color-secondary !important;
    }

    .button {
      position: absolute;
      transition: all .5s ease;
      bottom: -70px;
      margin: 0 auto;
      left: 0;
      right: 0;
      max-width: 155px;
      text-align: center;

      &:hover {
        background-color: $color-green30;
        color: $color-secondary;
      }
    }


    @include respond-min($md){
      &:nth-child(1), &:nth-child(3), &:nth-child(6), &:nth-child(8) {
        &:before {
          content: '';
          background-color: rgba($color-darkgreen, .7) !important;
          position: absolute;
          height: 100%;
          width: 100%;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;

          .BW & {
            background: rgba($color-white, .8) !important;
          }
        }

        .info {
          background-color: $color-secondary !important;
        }

        .button {
          position: absolute;
          transition: all .5s ease;
          bottom: -70px;
          margin: 0 auto;
          left: 0;
          right: 0;
          max-width: 155px;
          text-align: center;
          color: $color-darkgreen;

          &:hover {
            background-color: $color-darkgreen30;
            color: $color-darkgreen;
          }
        }
      }
    }

    @include respond-max(991px){
      &:nth-child(even) {
        &:before {
          content: '';
          background-color: rgba($color-darkgreen, .7) !important;
          position: absolute;
          height: 100%;
          width: 100%;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;

          .BW & {
            background: rgba($color-white, .8) !important;
          }
        }

        .info {
          background-color: $color-secondary !important;
        }

        .button {
          position: absolute;
          transition: all .5s ease;
          bottom: -70px;
          margin: 0 auto;
          left: 0;
          right: 0;
          max-width: 155px;
          text-align: center;
          color: $color-darkgreen;

          &:hover {
            background-color: $color-darkgreen30;
            color: $color-darkgreen;
          }
        }
      }
    }

    .interview__image {
      padding-bottom: 75%;
      display: block;

      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;

      .BW & {
        filter: grayscale(100%) !important;
        -webkit-filter: grayscale(100%) !important;
        -moz-filter: grayscale(100%) !important;
        -o-filter: grayscale(100%) !important;
        -ms-filter: grayscale(100%) !important;

        &:after {
          content: '';
          background: rgba($color-white, .5) !important;
          position: absolute;
          height: 100%;
          width: 100%;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          z-index: -1;
        }

        .info {
          h2, h3 {
            color: $color-black;
          }

          background: $color-white !important;
        }
      }

      .info {
        transition: all .5s ease;
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        width: 75%;
        left: 0;
        right: 0;
        background: $color-blue;
        border-radius: 20px 20px 0 0;
        padding: 15px;

        h2 {
          font-size: 18px;
          font-weight: $font-bold !important;
          color: $color-white;
          margin-bottom: 0px;
        }

        h3 {
          color: $color-text-dark;
          font-size: 18px;
          font-weight: $font-light;
          margin-bottom: 0px;
        }
      }
    }
  }

}

.interviews {
  .interviews__item {
    display: block;
    overflow: hidden;

    &:nth-child(even) {
      background-color: $color-green;

      .BW & {
        background-color: lighten($color-black, 30%);
      }

      .content__col {
        float: left;
      }

      .image__col {
        float: right;
      }
    }

    &:nth-child(odd) {
      background-color: $color-darkgreen;

      .BW & {
        background-color: lighten($color-black, 20%);
      }

      .content__col {
        float: right;

        h3 {
          color: $color-white !important;
        }

        .button {
          background-color: $color-white;
          color: $color-darkgreen;

          .BW & {
            background-color: $color-white !important;
            color: $color-black !important;

            @include respond-min($md) {
              &:hover {
                background-color: $color-white !important;
                color: $color-black !important;
                text-decoration: underline;
              }
            }
          }

          @include respond-min($md) {
            &:hover {
              background-color: $color-primary;
              color: $color-white;
            }
          }
        }

      }

      .image__col {
        float: left;
      }
    }

    .content__col {
      .inner {
        max-width: 90%;
        margin: 0 auto;

        h2 {
          font-family: $font-serif;
          font-weight: $font-serif-black;
          color: $color-white;
          font-size: 32px;
          line-height: 46px;

          @include respond-max(480px) {
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 5px;
          }
          @include respond-between(481px, 768px) {
            font-size: 28px;
            line-height: 30px;
          }
          @include respond-between(769px, 990px) {
            font-size: 32px;
            line-height: 38px;
          }
        }

        h3 {
          font-size: 18px;
          color: $color-primary;

          .BW & {
            color: $color-white;
          }

          @include respond-max(480px) {
            font-size: 16px;
          }
          @include respond-between(481px, 768px) {
            font-size: 16px;
          }
        }
      }

    }

    .image__col {
      padding-left: 0px;
      padding-right: 0px;

      .interviews__image {
        .image {
          background-size: cover;
          background-position: center !important;
          display: block;
          opacity: .5;
        }
      }
    }

  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
