/**
* Layout/hero stylesheet
*
* Table of contents
* 1) Block
* 2) Element
* 3) Modifier
*/

/**
* 1) Block
*/

.hero__img-small {
  .hero__item {
    z-index: -1;
    position: relative;
    width: 100%;
    height: 390px;
    display: block;
    background-size: cover;
    background-position: center;
    outline: none;
    border-radius: 15px 15px 0 0;
    user-select: text !important;
    box-shadow: 0 0 35px rgba(0, 0, 0, .5);

    h2 {
      color: $color-white;
      font-size: 50px;
      font-family: $font-serif;
      font-weight: $font-serif-black;
      text-shadow: 2px 2px 10px rgba($color-black, .5);
      z-index: 1;
      top: 140px;
      position: absolute;
      user-select: text !important;

      span {
        display: inline-block;
        line-height: 1.2em;
        padding: .05em .1em;

        .BW & {
          background-color: $color-black;
        }
      }

      @include respond-min($md) {
        font-size: 50px !important;
        padding-top: 210px;
      }
      @include respond-between($md, $lg) {
        font-size: 40px !important;
      }
      @include respond-between($sm, $md) {
        font-size: 30px;
      }
      @include respond-between($xs, $sm) {
        font-size: 38px !important;
        padding: 0 10px;
      }
      @include respond-max(767px) {
        font-size: 28px !important;
        padding: 0 10px;
      }
    }

    @include respond-between($sm, $md) {
      height: 300px !important;
    }
  }

  @include respond-max(767px) {
    .container {
      padding: 0 !important;

      .row {
        margin-left: 0;
        margin-right: 0;

        .col-full, .col-lg-12 {
          padding: 0 !important;

          .hero__item {
            border-radius: 0;
            height: 185px;
            margin-top: -10px;
            background-image: none !important;

            .has-notification & {
              @include respond-max(767px) {
                height: 200px;
              }
            }

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.hero__img {
  position: relative;
  background-color: $color-black;

  .vestiging__col {
    top: 90px;
    position: relative;
    background-color: $color-white;
    padding: 30px 30px 30px 30px;
    border-radius: 10px;
    color: $color-primary;
    font-size: 18px;

    sup, sub {
      position: relative;
      top: 0px;
    }

    .BW & {
      background-color: $color-white !important;

      h2 {
        span {
          background: none !important;
        }
      }

      h2, p, a, h1, h3, h4 {
        color: $color-black !important;
      }

      &.contact {
        h2 {
          border-color: $color-black !important;
        }
      }
    }

    @include respond-min($lg) {
      max-width: 292px;
    }

    p {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .fa {
      width: 20px;
      text-align: center;
      font-size: 20px;
      font-family: Fontawesome;

      &.fa-envelope {
        font-size: 16px;
      }
    }

    @include respond-min($md) {
      a {
        &:hover {
          color: $color-darkgreen;
        }
      }
    }

  }

  .hero__item {
    z-index: 0;
    width: 100%;
    height: 550px;
    display: block;
    background-size: cover;
    background-position: center;
    outline: none;
    user-select: text !important;

    &.buttons {
      .info {
        position: absolute;
        top: 170px;

        @include respond-min($md) {
          top: 260px;
        }
      }

      .box {
        @include respond-between(481px, 768px) {
          padding: 0 10px;
          margin: 0 auto;

          .button {
            margin-right: 10px;
            width: 45% !important;
          }
        }

        @include respond-max(480px) {
          padding: 0 10px;
          width: 100%;
          margin: 0 auto;

          .button {
            display: block;
            width: auto !important;
          }
        }

        .button {
          margin-bottom: 10px !important;
          text-align: center;
          width: 170px;
          box-sizing: border-box;
          padding: 0;

          .held & {
            background-color: $color-held;
            color: $color-white;
            border-color: $color-held;
            cursor: pointer;

            @include respond-min($md) {
              &:hover {
                background-color: $color-green;
                color: $color-white;
                border-color: $color-green;
              }
            }
          }

          @include respond-min($sm) {
            margin-right: 10px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }

    }

    .info {
      position: absolute;
      top: 200px;

      @include respond-min($md) {
        top: 260px;
      }
    }

    h3 {
      color: $color-secondary;
      font-size: 28px;
      font-family: $font-serif;
      font-weight: $font-serif-regular;
      text-shadow: 2px 2px 10px rgba($color-black, .5);
      z-index: 2;
      margin-bottom: 0px;

      .held & {
        color: $color-white;
      }

      @include respond-max($xs) {
        margin-top: 20px;
      }

      span {
        display: inline-block;
        line-height: 1.2em;
        padding: .05em .1em;
      }

      @include respond-min($md) {
        font-size: 28px;
      }
      @include respond-between($md, $lg) {
        font-size: 26px;
      }
      @include respond-between($xs, $sm) {
        font-size: 26px;
      }
      @include respond-max(768px) {
        font-size: 22px;
        padding: 0 10px;
      }

      .BW & {
        color: $color-bg;
      }
    }

    h2 {
      color: $color-white;
      font-size: 42px;
      font-family: $font-serif;
      font-weight: $font-serif-black;
      text-shadow: 2px 2px 10px rgba($color-black, .5);
      z-index: 2;

      .held & {
        span:nth-child(1) {
          padding-left: 0;
        }

        span:nth-child(2) {
          color: $color-green;
        }
      }

      @include respond-min($sm) {
        margin-bottom: 35px;
      }

      span {
        display: inline-block;
        line-height: 1.2em;
        padding: .05em .1em;
      }

      @include respond-min($md) {
        font-size: 42px;
      }
      @include respond-between($md, $lg) {
        font-size: 30px;
      }
      @include respond-between($xs, $sm) {
        font-size: 28px;
        padding: 0 10px;
      }
      @include respond-max(480px) {
        font-size: 22px;
        padding: 0 10px;
      }
    }

    @include respond-min($md) {
      height: 700px !important;
    }
    @include respond-between($sm, $md) {
      height: 500px !important;
    }
  }
}

.slider {
  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 550px;

  @include respond-max(480px) {
    .has-notification & {
      top: 0px;
    }
  }

  @include respond-min($md) {
    height: 700px;
  }

  .BW & {
    .hero__item {
      filter: grayscale(100%) !important;

      &:before {
        background: rgba(0, 0, 0, .6);
      }

      h2, h3 {
        span {
          z-index: 2;
          position: relative;
          color: $color-white !important;
          background-color: $color-black;
        }
      }
    }
  }

  @include respond-min($md) {
    margin-top: 0px;
    width: 100%;
    position: static;
  }

  .hero__item {
    z-index: 0;
    width: 100%;
    height: 550px;
    display: block;
    background-size: cover;
    background-position: center;
    outline: none;
    user-select: text !important;

    .info {
      position: absolute;
      top: 200px;

      @include respond-min($md) {
        top: 290px;
      }
    }

    h3 {
      color: $color-secondary;
      font-size: 28px;
      font-family: $font-serif;
      font-weight: $font-serif-regular;
      text-shadow: 2px 2px 10px rgba($color-black, .5);
      z-index: 2;
      margin-bottom: 10px;

      span {
        display: inline-block;
        line-height: 1.2em;
        padding: .05em .1em;
      }

      @include respond-min($md) {
        font-size: 28px;
      }
      @include respond-between($md, $lg) {
        font-size: 26px;
      }
      @include respond-between($xs, $sm) {
        font-size: 26px;
      }
      @include respond-max(767px) {
        font-size: 22px;
        padding: 0 10px;
      }
    }

    h2 {
      color: $color-white;
      font-size: 36px;
      font-family: $font-serif;
      font-weight: $font-serif-black;
      text-shadow: 2px 2px 10px rgba($color-black, .5);
      z-index: 2;

      span {
        display: inline-block;
        line-height: 1.2em;
        padding: .05em .1em;
      }

      @include respond-min($md) {
        font-size: 36px;
      }
      @include respond-between($md, $lg) {
        font-size: 30px;
      }
      @include respond-between($xs, $sm) {
        font-size: 28px;
      }
      @include respond-max(767px) {
        font-size: 22px;
        padding: 0 10px;
      }
    }

    @include respond-min($md) {
      height: 700px !important;
    }
    @include respond-between($sm, $md) {
      height: 550px !important;
    }
  }
}

.slick-dots {
  display: none !important;
}

/**
* 2) Element
*/

/**
* 3) Modifier
*/
