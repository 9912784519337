/**
* Interface/navigation stylesheet
*
* Table of contents
* 1) Block
* 2) Element
* 3) Modifier
*/

/**
* 1) Block
*/

.nav {
  display: none;
  width: 100%;
  height: calc(100vh - 75px);
  background-color: $color-white;
  position: fixed;
  top: 75px;
  left: 0;
  z-index: $z-index-4;
  overflow-y: auto;

  .BW & {
    ul {
      background: $color-black !important;
      color: $color-white !important;

      .nav__item {

        .sub-toggle {
          color: $color-white !important;
        }

        > &.selected {

          > .nav__link {
            color: $color-black !important;
            text-decoration: underline;
          }
        }

        @include respond-min($md) {
          &:hover {
            &:first-child {
              > .nav__link {
                &:after {
                  display: none;
                }
              }
            }

            > .nav__link {
              color: $color-white !important;
              text-decoration: underline;
            }
          }
        }

        .nav__link {
          color: $color-white !important;
          text-decoration: none;

          &:after {
            border-color: $color-white !important;
          }
        }
        ul {
          background-color: $color-white !important;
          &:before {
            background: $color-black !important;
          }
          .nav__item {

            &.selected {
              a {
                text-decoration: underline !important;
              }
            }
            @include respond-min($md) {
              &:hover {
                a {
                  text-decoration: underline !important;
                }
              }
            }
            a {
              color: $color-black !important;
            }
          }

        }
      }
    }

    @include respond-max(991px) {
      background-color: $color-black;
    }

  }

  @include respond-max(991px) {
    background-color: $color-green;
    padding-bottom: 100px;

    .mergetabs {
      ul {
        margin-top: 70px;

        li {
          background-color: rgba($color-secondary, .8);
          padding: 10px;
          color: $color-white;
          transition: all .3s ease;
          display: block;

          a {
            color: $color-white;
            text-decoration: none;
            font-family: $font-serif;
            display: inline-block;
            font-size: 20px;
            position: relative;
            width: calc(100% - 70px);
            transition: all .3s ease;
          }

          &:nth-child(2) {
            background-color: rgba($color-blue, .8);
          }

          i {
            font-size: 22px;
            width: 35px;
            margin-right: 10px;
            text-align: center;
            color: $color-white;
          }

        }
      }
    }

    .nav__secondary {
      padding: 20px;
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: $color-white;

      > .nav__item {
        min-height: 50px;
        line-height: 50px;
        border-bottom: 1px dotted $color-green;
        position: relative;

        &:first-child {
          border-top: 1px dotted $color-green;
        }

        &.selected {
          .nav__link {
            color: $color-primary;
            font-weight: $font-bold;
          }
        }

        .sub-toggle {
          display: none;
        }

        > .nav__link {
          text-decoration: none;
          color: $color-darkgreen;
          font-size: 18px;
          line-height: 50px;
        }

      }
    }

    .nav__primary {
      padding: 20px;
      padding-top: 100px;
      padding-bottom: 40px;

      > .nav__item {
        min-height: 50px;
        line-height: 50px;
        border-bottom: 1px dotted $color-primary;
        position: relative;

        &:first-child {
          border-top: 1px dotted $color-primary;
        }

        &.selected {
          .nav__link {
            color: $color-primary;
          }
        }

        .sub-toggle {
          color: $color-primary;
          line-height: 43px;

          @include respond-min($md) {
            display: none !important;
          }
        }

        > .nav__link {
          text-decoration: none;
          color: $color-white;
          font-size: 22px;
          line-height: 50px;
          font-weight: $font-serif-black;
          font-family: $font-serif;
          padding: 0;
        }

        &.has-sub {

          .nav__sub {
            padding: 0 0 20px 0;

            .nav__item {
              min-height: 30px;
              line-height: 30px;

              &.selected {
                .nav__link {
                  color: $color-white;
                }
              }
              .nav__link {
                min-height: 30px;
                line-height: 30px;
                font-size: 18px;
                color: $color-primary;
                padding: 0;
                text-decoration: none;
              }
            }
          }
        }

      }
    }

  }

  @include respond-min($sm) {
    display: block;
    width: 350px;
    height: 100vh;
    transition: all .3s ease;
    top: 0px;
    left: -350px;
    padding-top: 80px;
  }

  @include respond-min($md) {
    width: 100%;
    height: auto;
    position: absolute;
    transition: none;
    overflow: visible;
    background: none;
    padding-top: 0;
    left: 15px;
    top: 160px;

    &.white {

      .nav__primary {
        background-color: $color-white;
        border-radius: 10px;
        height: 60px;

        display: flex;
        flex-wrap: wrap;
        text-align: center;
        position: relative;
        box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);

        > .nav__item {
          flex-grow: 1;
          position: static;
          height: 60px;

          &:hover {
            > .nav__link {
              background-color: $color-green;
              color: $color-white;

              &:after {
                display: none;
              }
            }

            &:last-child {
              > .nav__link {
                border-radius: 0 8px 8px 0;
              }
            }
            &:first-child {
              > .nav__link {
                border-radius: 8px 0 0 8px;
              }
            }

            &.has-sub {
              &:last-child {
                > .nav__link {
                  border-radius: 0 8px 0 0;
                }
              }
              &:first-child {
                > .nav__link {
                  border-radius: 8px 0 0 0;
                }
              }
            }
          }

          > .nav__link {
            position: relative;
            font-size: 22px;
            font-family: $font-serif;
            font-weight: $font-serif-bold;
            color: $color-green;
            text-decoration: none;
            line-height: 60px;
            height: 60px;
            width: 100%;
            z-index: 5;

            @include respond-between($md, $lg) {
              font-size: 20px;
            }

            &:after {
              content: "";
              height: 30px;
              border-right: 1px dotted $color-green;
              position: absolute;
              top: 25%;
              right: -1px;
            }
          }

          &:last-child {
            .nav__link {
              &:after {
                display: none;
              }
            }
          }

          &.has-sub {

            @include respond-max(991px) {
              .sub-toggle {
                display: inline-block;
                color: $color-primary;
                line-height: 50px;
                font-size: 20px;
                right: 0;
                width: 12px;
                position: relative;
                padding: 0px;
              }
            }

            .nav__sub {
              background: $color-green;
              width: 100%;
              column-count: 2;
              z-index: 4;
              border-radius: 0 0 15px 15px;
              padding: 30px 45px;
              box-shadow: 0 1px 10px rgba($color-black, .1);

              .nav__item {
                width: 100%;

                &.selected, &:hover {
                  .nav__link {
                    color: $color-primary;
                    text-decoration: none;
                  }
                }

                .nav__link {
                  width: 100%;
                  color: $color-white;
                  font-size: 18px;
                  line-height: 30px;
                  min-height: 30px;
                }
              }

              &:before {
                content: "";
                width: 100%;
                height: 10px;
                background: $color-white;
                position: absolute;
                top: -10px;
                left: 0;
              }
            }
          }

        }
      }
    }

    &.green {
      background-color: $color-green;
      border-radius: 10px;

      .colors.small--menu & {
        @include respond-between($md, $lg) {
          max-width: 270px !important;
        }
      }

      .colors & {
        background: none;
        top: auto !important;
        bottom: -409px;

        .nav__primary {
          border-radius: 10px 10px 0 0;
        }
      }

      .has-notification &{
        bottom: -411px;
      }

      .small--menu & {
        max-width: 310px;
      }

      .header--small & {
        background-color: $color-white;
      }

      .nav__primary {
        background-color: $color-green;
        border-radius: 10px;
        position: relative;
        padding: 30px;

        > .nav__item {
          height: 40px;
          width: 100%;

          &:first-child {
            .nav__link {

              &:before {
                content: "";
                border-top: 1px dotted $color-primary;
                width: 100%;
                display: block;

                .BW & {
                  border-color: $color-white !important;
                }

                .header--small & {
                  display: none;
                }
              }
            }
          }

          &:hover, &.selected {
            > .nav__link {
              color: $color-primary;
            }
          }

          > .nav__link {
            padding: 0px;
            position: relative;
            font-size: 22px;
            font-family: $font-serif;
            font-weight: $font-serif-bold;
            color: $color-white;
            text-decoration: none;
            line-height: 40px;
            height: 40px;
            width: 100%;
            z-index: 5;

            @include respond-between($md, $lg) {
              font-size: 18px;
            }

            &:after {
              content: "";
              border-bottom: 1px dotted $color-primary;
              width: 100%;
              display: block;
            }
          }

          &.has-sub {

            @include respond-max(991px) {
              .sub-toggle {
                display: none;
                color: $color-primary;
                line-height: 50px;
                font-size: 20px;
                right: 0;
                width: 12px;
                position: relative;
                padding: 0px;
              }
            }

            .nav__sub {
              display: none;
            }

          }
        }
      }
    }
  }
}

.menu-is-active {

  .BW & {
    @include respond-min($sm) {
      &:after {
        background: rgba($color-black, .9) !important;
      }
    }
  }

  .options {
    display: none;
  }
  .contact {
    opacity: 0;
  }
  .header {
    position: fixed;
    height: 165px;
  }

  @include respond-max($sm) {
    &.BW {
      &:after {
        background: $color-black;
      }
    }
    &:after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: $color-green;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 19;
    }
  }
  @include respond-between($sm, $md) {
    .header {
      position: relative;
      .logo__wrapper {
        position: fixed;
      }
    }
    &:after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, .8);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $z-index-1;
    }
  }

  .nav {
    display: block;

    @include respond-between($sm, $md) {
      box-shadow: 5px 0 5px -2px $color-black;
      top: 0;
      left: 0;
      padding-top: 80px;
    }
  }
}

.left__nav {
  background-color: $color-white;
  padding: 30px;
  border-radius: 10px;
  overflow: hidden;

  .donotshowleftnav {
    display: none !important;
  }

  h2 {
    font-weight: $font-serif-black;
    font-family: $font-serif;
    color: $color-darkgreen;
    font-size: 25px !important;
    border-bottom: dotted 1px $color-darkgreen;
    padding-bottom: 5px;
  }

  ul {
    margin: 0;
    display: block;
    width: 100%;
    position: relative;

    .nav__item {
      display: block;
      width: 100%;
      line-height: 20px;
      height: auto !important;
      min-height: auto !important;

      @include respond-max(767px) {
        height: auto !important;

        .nav__link {
          height: auto !important;
          min-height: auto !important;
        }
      }

      &.selected {
        > a {
          color: $color-darkgreen;
          font-weight: $font-bold;
          text-decoration: none !important;

          .BW & {
            color: $color-black;
          }
        }

        .nav__sub {
          margin-left: 10px;
          font-size: 17px;

          .nav__item {
            &.selected {
              > a {
                color: $color-darkgreen;
                font-weight: $font-bold;
                text-decoration: none !important;

                .BW & {
                  color: $color-black;
                }
              }
            }
          }

        }

      }

      .nav__link {
        padding: 4px 0;
        line-height: 20px;
        height: auto !important;
        color: $color-primary;
        text-decoration: underline;
        display: block;
        min-height: auto !important;

        @include respond-min($md) {
          &:hover {
            color: $color-darkgreen;
            text-decoration: none !important;
          }
        }
      }
    }
  }

}

/**
* 2) Element
*/

.nav__primary {
  .BW & {
    background-color: $color-black;
    border-radius: 10px;

    @include respond-max(991px) {
      > .nav__item {
        border-color: rgba($color-white, 0.3);

        &.selected {
          .nav__link {
            color: $color-white;
            text-decoration: underline;
          }
        }

        .nav__sub {
          background-color: $color-black !important;
          .nav__item {
            &.selected {
              .nav__link {
                color: $color-white !important;
                text-decoration: underline;
              }
            }
            .nav__link {
              color: $color-white !important;
            }
          }
        }
      }
    }

  }
}

//.BW {
//  .listen, .contrast {
//    background-color: $color-black;
//    border-radius: 10px;
//    padding: 0 10px;
//    height: 30px !important;
//    line-height: 30px !important;
//  }
//}

.nav__secondary {
  margin-bottom: 30px;

  .BW & {

    .nav__item--secondary {
      .nav__link {
        background-color: $color-black;
      }
    }

    .listen, .contrast {
      background-color: $color-black;
      border-radius: 10px;
      padding: 0 10px;
    }

    a {
      color: $color-white !important;

      @include respond-min($md) {
        &:hover {
          color: $color-white !important;
          text-decoration: underline;
        }
      }
      &.selected {
        color: $color-white;
        text-decoration: underline;
      }
    }

    @include respond-max(991px) {
      > .nav__item {
        border-color: rgba($color-white, 0.3);

        &.selected {
          .nav__link {
            color: $color-white;
            text-decoration: underline;
          }
        }

        &:first-child {
          border-color: rgba($color-white, 0.3);
        }
      }
    }

  }

  .home &, .page & {
    @include respond-min($md) {
      .nav__link {
        text-shadow: 2px 2px 10px $color-black;
      }
      .listen, .contrast {
        text-shadow: 2px 2px 10px $color-black;
      }
    }
  }

  @include respond-between($md, $lg) {
    padding: 0;
  }

  .contrast, .listen {
    text-align: right;
    color: $color-green;
    text-decoration: none;

    @include respond-between($md, $lg) {
      padding-left: 20px;
    }

    .fa {
      color: $color-white;
      font-size: 14px;
    }

    a {
      font-size: 14px;
      text-decoration: none;

      @include respond-min($md) {
        &:hover {
          text-decoration: underline;
          color: $color-green;
        }
      }
    }
  }

  .listen {
    padding-left: 25px;
    @include respond-between($md, $lg) {
      padding-left: 5px;
    }
  }

  .nav__item--secondary {
    @include respond-min($md) {
      padding-left: 20px;
    }

    @include respond-between($md, $lg) {
      padding-right: 0;
    }

    .nav__link {
      text-align: left;
      text-shadow: none !important;
      color: $color-white;
      text-decoration: none;
      font-size: 18px;
      font-family: $font-serif;
      font-weight: $font-serif-bold;
      padding: 0px;

      .small--menu & {
        font-size: 22px !important;
      }

      @include respond-min($md) {
        line-height: 40px;
        height: 40px;
        border: 1px solid $color-white;
        padding: 0 30px;
        border-radius: 10px;
        background-color: rgba($color-primary, .2);

        &:hover {
          background-color: rgba($color-primary, .8);

          .BW & {
            background-color: $color-white !important;
            color: $color-black !important;
          }
        }
      }
    }
  }
}

.options {
  position: absolute;
  top: 7px;
  right: 100px;
  z-index: 2;
  padding: 0 10px;
  width: 250px;

  .BW & {
    border-radius: 10px;
    padding: 0 10px;

    li {
      background-color: $color-black;
      margin-right: 3px;

      &:last-child {
        margin-right: 0px;
      }
      a {
        line-height: 30px !important;
        min-height: 30px !important;
        color: $color-white;
      }
    }
  }

  @include respond-max(767px) {
    top: 145px;
    width: calc(100% - 15px) !important;
    right: 0 !important;
    text-align: left;
    left: 10px;

    li {
      display: inline-block !important;
      float: none;
    }
  }

  @include respond-max($md) {
    width: auto;
    right: 75px;
  }

  li {
    float: left;
    display: inline-block;

    @include respond-max(767px) {
      display: block;
      float: none;

      a {
        min-height: 30px !important;
        line-height: 30px !important;
        width: auto !important;
      }
    }

    li {
      display: inline-block;
    }
    .fa {
      display: inline-block;
    }
    a {
      color: $color-white;
      display: inline-block;
    }
  }

  .contrast, .listen {
    text-align: right;

    @include respond-between($md, $lg) {
      padding-left: 20px;
    }

    .fa {
      color: $color-white;
      font-size: 14px;
    }

    a {
      font-size: 14px;

      @include respond-min($md) {
        &:hover {
          text-decoration: underline;
          color: $color-green;
        }
      }
    }
  }

  .listen {
    padding-left: 10px;

    @include respond-min($md) {
      padding-left: 25px;
    }
    @include respond-between($md, $lg) {
      padding-left: 5px;
    }
  }
}

.nav__sub {
  display: none;

  /**
   * Use the below code when using delay in showing the sub-menu.
   * Please make sure to disable/delete `display: none;`
   */
  // visibility: hidden;

  .submenu-is-active > & {
    display: block;

    /**
     * Use the below code when using delay in showing the sub-menu.
     * Please make sure to disable/delete `display: block;`
     */
    // visibility: visible;
  }

  @include respond-min($md) {
    width: 100%;
    background-color: $color-white;
    text-align: left;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $z-index-5;

    /**
     * Use the below code when using delay in showing the sub-menu.
     */
    // transition: visibility .25s ease;

    .submenu-is-active & {
      display: none;

      /**
       * Use the below code when using delay in showing the sub-menu.
       * Please make sure to disable/delete `display: none;`
       */
      // visibility: hidden;
    }
  }
}

.nav__item {

  .sub-toggle {
    display: none;
  }

  &.has-sub {
    position: relative;

    @include respond-max(991px) {
      .sub-toggle {
        display: inline-block !important;
      }
    }

    @include respond-min($md) {
      &:hover .nav__sub {
        display: block;

        /**
         * Use the below code when using delay in showing the sub-menu.
         * Please make sure to disable/delete `display: block;`
         */
        // visibility: visible;
        // transition-delay: .25s;
      }
    }
  }

  @include respond-min($md) {
    display: inline-block;

    &:last-child .nav__sub {
      right: 0;
      left: auto !important;
    }
  }
}

.nav__link {
  display: block;
  padding: 0 .5em;
  min-height: 40px;
  line-height: 40px;
  //width: 80%;

  @include respond-min($md) {
    display: inline-block;
  }
}

/**
* 3) Modifier
*/

.nav__link--sub {
  padding: 0 1.5em;

  @include respond-min($md) {
    padding: 0 0.5em;
  }
}

