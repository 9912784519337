/**
 * Modules/news stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

section.new {
  h2, h1 {
    .BW & {
      color: $color-black;
    }
  }

  .item {
    h3 {
      margin-bottom: 0px;
      font-size: 20px;
      color: $color-primary;
      font-weight: $font-bold;

      .BW & {
        color: $color-black;
      }
    }

    .date {
      font-size: 16px;

      .BW & {
        color: $color-black;
      }
    }

    a {
      text-decoration: none;
      color: $color-primary;

      .BW & {
        color: $color-black;
      }

      @include respond-min($md) {
        &:hover {
          color: $color-text-dark;

          .BW & {
            color: $color-black;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.share {
  p, .addthis_inline_share_toolbox {
    display: inline-block;
  }

  .addthis_inline_share_toolbox {
    .at-share-btn {
      margin-right: 8px;
      background-color: lighten($color-black, 60%) !important;

      .BW & {
        background: $color-black !important;
      }
    }
  }

  p {
    top: -13px;
    position: relative;
    margin-right: 5px;

    .fa {
      color: lighten($color-black, 60%);
      margin-left: 8px;
      font-size: 30px;
      position: relative;
      top: 5px;

      .BW & {
        color: $color-black;
      }
    }
  }
}

.news-pages {
  color: transparent;
  display: block;
  float: right;
  margin-bottom: 30px;
  display: block;
  width: 100%;
  text-align: right;
  border-bottom: 1px solid $color-bg;
  padding-bottom: 20px;

  .next, .previous {
    display: none;
  }

  .item {
    background: darken($color-bg, 10%);
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    margin: 0 2px;

    @include respond-min($md) {
      &:hover {
        background-color: $color-primary;

        a {
          color: $color-white;
        }
      }
    }

    a {
      text-decoration: none !important;
      color: $color-primary;
      display: block;

      @include respond-min($md) {
        &:hover {
          color: $color-white;
        }
      }
    }

    &.selected {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.news {
  background-color: $color-blue;
  color: $color-text-dark;

  .BW & {
    background-color: $color-white;
    color: $color-black;

    .inner {
      &:after {
        background-color: $color-bg;
      }

      .item {
        h2, h1, p, a, .date {
          color: $color-white !important;
        }
      }

      h1, p, a, .date {
        color: $color-black !important;

        &.js-toggle-left-navigation, &.js-toggle-left-navigation .fa {
          color: $color-white !important;
        }

      }

      h3 {
        color: $color-black !important;

        a {
          color: $color-black !important;

          @include respond-min($md) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      ul {
        li {
          a {
            color: $color-black;

            @include respond-min($md) {
              &:hover {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }

  h2 {
    font-family: $font-serif;
    color: $color-white;
    font-size: 32px;
  }

  .image__item {
    .image {
      background-size: cover;
      padding-bottom: 85%;
      position: relative;
      display: block;
      border-radius: 20px;
    }
  }

  .item {
    h3 {
      margin-bottom: 0px;
      color: $color-white;
      font-size: 20px;
      font-weight: $font-bold;
    }

    .date {
      font-size: 16px;
    }

    a {
      text-decoration: none;
      color: $color-white;

      @include respond-min($md) {
        &:hover {
          color: $color-text-dark;
        }
      }
    }
  }

  .new {
    @include respond-min($md) {
      padding-left: 50px;
    }
  }

  .share {
    p, .addthis_inline_share_toolbox {
      display: inline-block;
    }

    .addthis_inline_share_toolbox {
      .at-share-btn {
        margin-right: 8px;
        background-color: $color-secondary !important;

        .BW & {
          background: $color-black !important;
        }
      }
    }

    p {
      top: -13px;
      position: relative;
      margin-right: 5px;

      .fa {
        color: $color-secondary;
        margin-left: 8px;
        font-size: 30px;
        position: relative;
        top: 5px;

        .BW & {
          color: $color-black;
        }
      }
    }
  }

  .agenda {
    h1 {
      font-size: 32px;
      color: $color-primary;

      .BW & {
        color: $color-black;
      }
    }

    p {
      margin-bottom: 0px;

      .BW & {
        color: $color-black;
      }
    }

    .date, .time {
      font-size: 16px;
      margin-bottom: 0px !important;

      .BW & {
        color: $color-black;
      }
    }

    h1 {
      margin-bottom: 5px;
    }

    h3 {
      color: $color-primary;
      font-size: 20px;
      font-weight: $font-bold;
      margin-bottom: 0px;

      .BW & {
        color: $color-black;
      }

      a {
        text-decoration: none;
        color: $color-primary;

        .BW & {
          color: $color-black;
        }

        @include respond-min($md) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .calendar {
    @include respond-min($md) {
      padding-right: 50px;
      border-right: 1px dotted $color-white;
    }

    .item {
      .date, .time {
        color: $color-white;
        font-weight: $font-light;
        font-size: 18px;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 0;
      }

      .fa {
        color: $color-white;
      }
    }
  }
}

.profielen {
  h2 {
    color: $color-primary;
    font-weight: $font-serif-black;
    font-family: $font-serif;
    font-size: 32px;
    margin-left: 2px;

    .BW & {
      color: $color-black;
    }
  }
}

.calendars {
  background-color: $color-text-dark30;

  .BW & {
    background-color: $color-bg;
    color: $color-black !important;

    h1, h2, h3, a, p, .date, .time, table {
      color: $color-black;
    }
  }

  @include respond-max(767px) {
    h2 {
      font-size: 24px !important;
    }
  }

  table {
    width: 100%;

    tbody {
      vertical-align: middle;
    }

    @include respond-max($sm) {
      tr {
        th {
          padding: 5px 20px 0px 20px !important;
        }

        td {
          padding: 5px 20px 0 20px !important;
        }
      }
    }

    th {
      background-color: $color-primary;
      color: $color-white;
      text-align: left;

      .BW & {
        background: $color-black !important;
      }

      &:first-child, &:last-child {
        padding-left: 20px;
      }
    }

    tr {
      color: $color-primary;
      border: 4px solid white;
      box-sizing: border-box;

      a {
        text-decoration: none;
      }

      @include respond-min($sm) {
        &:hover {
          background: $color-secondary !important;
          color: $color-white !important;
          cursor: pointer;

          a {
            text-decoration: none;
            color: $color-white !important;
          }

          .BW & {
            background: lighten($color-black, 20%) !important;
            color: $color-white !important;

            a {
              color: $color-white !important;
            }
          }
        }

        &:first-child {
          height: 40px;
          line-height: 40px;
          font-family: $font-serif;
          font-weight: $font-serif-black;

          .BW & {
            background: $color-black !important;
          }
        }

        td {
          padding: 15px;
        }

        @include respond-max(768px) {
          a {
            padding-top: 4px;
            display: block;
          }
        }

        @include respond-min($md) {

        }
      }

      .BW & {
        color: $color-black;
      }

      &:first-child {
        background-color: $color-primary !important;

        .BW & {
          background: $color-black;
        }
      }

      &:nth-child(even) {
        background-color: $color-bg-light;
      }

      &:nth-child(odd) {
        background-color: $color-bg;
      }

      td {
        //&:first-child {
        //  a {
        //    color: $color-text-dark70;
        //    @include respond-max(768px) {
        //      font-size: 14px;
        //    }
        //  }
        //}
        //&:nth-child(2) { }

        font-weight: $font-bold;
        font-size: 20px;

        @include respond-between(768px, 991px) {
          font-size: 16px !important;
        }
        @include respond-max(768px) {
          &:nth-child(2) {
            padding-top: 0 !important;
            padding-bottom: 8px !important;
            font-size: 16px !important;
          }
        }

        &:last-child {
          a {
            color: $color-text-dark70;
          }

          padding-right: 20px;

          .fa {
            font-size: 30px;
            position: relative;
            top: 5px;
          }
        }

      }
    }
  }

  h2 {
    font-family: $font-serif;
    font-size: 32px;
    color: $color-primary;
  }

  p {
    color: $color-primary;
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
