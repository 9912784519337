/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */


/**
 * 1) Block
 */

.video__held {

  .video {
    position: relative;
    text-decoration: none;

    @include respond-min($md) {
      &:hover {
        .icon {
          transition: all .2s ease;
          font-size: 72px;
          left: -20px;
          color: $color-green70;

          @include respond-between($xs, 767px) {
            font-size: 62px;
          }
          @include respond-max(700px) {
            font-size: 48px;
            left: 0;
          }
        }

        .image__bg {
          &:before {
            transition: all .2s ease;
            opacity: 1;
          }
        }
      }
    }
  }

  .text {
    position: absolute;
    top: 50%;
    left: 38%;
    z-index: 1;
    transform: translateY(-50%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-max(700px) {
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      top: 55%;
      left: 50%;
      min-width: 245px;
    }

    .content {
      padding-left: 70px;

      h1 {
        font-size: 48px;
        color: white;

        .BW & {
          color: $color-white !important;
        }

        @include respond-between($sm, 991px) {
          font-size: 32px;
        }
        @include respond-between($xs, 767px) {
          font-size: 26px;
        }
        @include respond-max(700px) {
          font-size: 24px;
        }
      }

      p {
        color: white;
        font-size: 28px;

        .BW & {
          color: $color-white !important;
        }

        @include respond-between($sm, 991px) {
          font-size: 24px;
        }
        @include respond-between($xs, 767px) {
          font-size: 20px;
        }
        @include respond-max(700px) {
          font-size: 20px;
        }
      }

      span {
        color: $color-green;

        .BW & {
          color: $color-white !important;
        }
      }

      * {
        margin-bottom: 0;
      }
    }

    .icon {
      position: absolute;
      left: -15px;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      transition: all .2s ease;
      font-size: 62px;

      .BW & {
        i {
          color: $color-white !important;
        }
      }

      @include respond-between($xs, 767px) {
        font-size: 52px;
      }

      @include respond-max(700px) {
        left: 0;
        font-size: 42px;
      }
    }
  }

  .image__bg {
    aspect-ratio: 3/1;
    overflow: hidden;
    position: relative;
    border-radius: 15px;

    @include respond-max(700px) {
      aspect-ratio: 7/4;
    }

    &:before {
      content: '';
      background: rgb(80, 102, 174);
      background: -moz-linear-gradient(270deg, rgba(80, 102, 174, 0.6) 0%, rgba(80, 102, 174, 0.34) 100%);
      background: -webkit-linear-gradient(270deg, rgba(80, 102, 174, 0.6) 0%, rgba(80, 102, 174, 0.34) 100%);
      background: linear-gradient(270deg, rgba(80, 102, 174, 0.6) 0%, rgba(80, 102, 174, 0.34) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5066ae", endColorstr="#5066ae", GradientType=1);
      transition: all .2s ease;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .BW & {
        content: '';
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(270deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.34) 100%);
        background: -webkit-linear-gradient(270deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.34) 100%);
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.34) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5066ae", endColorstr="#5066ae", GradientType=1);
      }
    }

    .label {
      width: 20%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      @include respond-max(700px) {
        width: 28%;
      }
    }

    .img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      display: block;
    }
  }
}

.teammembers {
  .membersrow {
    @include respond-between(480px, 767px) {
      display: flex
    ;
      flex-wrap: wrap;
    }
  }

  .member__item {
    text-decoration: none;
    position: relative;
    margin-bottom: 30px;

    @include respond-between(480px, 767px) {
      max-width: 400px;
      margin: 0 auto;

      .row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
        row-gap: 10px;
      }
    }

    .inner {
      @include respond-max(479px) {
        background-color: $color-held;
        border-radius: 15px;
        height: 100%;
        position: relative;

        .row {
          display: flex;
          flex-wrap: nowrap !important;
          justify-content: center;
          align-items: flex-start;
          column-gap: 10px;
          row-gap: 10px;
        }
      }

      @include respond-between(480px, 767px) {
        background-color: $color-held;
        border-radius: 15px;
        height: 100%;
        position: relative;

        .row {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          column-gap: 10px;
          row-gap: 10px;
        }
      }
    }

    @include respond-min($md) {
      &:hover {
        .image {
          &:before {
            opacity: 1;
            transition: all .2s ease;
          }
        }

        .infocol {
          opacity: 1;
          transition: all .2s ease;
        }
      }
    }

    @include respond-max(991px) {
      margin-bottom: 20px;

      .image {
        &:before {
          opacity: 1 !important;
          transition: all .2s ease;
        }
      }

      .infocol {
        opacity: 1 !important;
        transition: all .2s ease;
      }
    }

    .infocol {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      padding: 30px 40px;
      opacity: 0;
      transition: all .2s ease;

      @include respond-max(767px) {
        position: relative;
        padding: 10px 5px 10px 0;
        align-self: center;
      }

      .content {
        align-content: flex-end;
      }

      .text {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        justify-content: center;

        .button {
          align-self: flex-end;
          padding: 0 20px;
          width: 100%;
          text-align: center;

          @include respond-max(767px) {
            font-size: 14px !important;
            padding: 0 10px;
          }
        }
      }

      h3 {
        font-size: 24px;
        font-family: $font-serif;
        margin-bottom: 10px;
        color: $color-white;

        @include respond-max(767px) {
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      p {
        font-size: 16px;
        color: $color-white;

        @include respond-max(767px) {
          font-size: 14px;
        }
      }
    }

    .image {
      aspect-ratio: 3/4;
      overflow: hidden;
      position: relative;
      border-radius: 15px;

      @include respond-min($sm) {
        &:before {
          content: '';
          background: rgb(80, 102, 174, .7);
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          opacity: 0;
          transition: all .2s ease;
        }
      }

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }

    .imagecol {
      @include respond-max(767px) {
        padding: 10px 0 10px 5px;
      }
    }
  }
}

/**
 * 2) Element
 */


/**
 * 3) Modifier
 */
