/**
 * Base/defaults stylesheet
 *
 * Table of contents
 * 1) Box-sizing
 * 2) HTML
 * 3) Body
 */

/**
 * 1) Box-sizing
 */

*,
*:before,
*:after {
  box-sizing: inherit;
  webkit-font-smoothing: antialiased;
}

/**
 * 2) HTML
 */

html {
  font-family: $font-sans;
  line-height: 1.5;
  font-size: 18px;
  box-sizing: border-box;
}

/**
 * 3) Body
 */
::selection {
  color: $color-white;
  background-color: $color-black;
}

body {
  position: relative;
  color: $color-text;
  background-color: $color-white;
  padding-top: 0;
  transition: background-color .3s ease;

  @include respond-min($md) {
    padding-top: 0px;

    &.header--small {
      padding-top: 115px;
      position: relative;
    }

    &.colors {
      padding-top: 110px;
      position: relative;
    }
  }

  @include respond-between($sm, $md) {
    &.colors {
      padding-top: 80px;
    }
  }
}

.no-scroll {
  height: 100%;
  overflow: auto;
}
