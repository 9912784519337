/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

.content.links {
  margin-top: 0;
  z-index: 0;
  position: relative;

  .BW & {
    .menu .inner, .locations .inner {
      color: $color-black;
      background: $color-white !important;

      h2, h1, .fa {
        color: $color-black;
      }

      h2 {
        border-color: $color-black;
      }

      a {
        color: $color-black;

        @include respond-min($md) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .inner {
    padding-bottom: 32px;
  }

  @include respond-min($md) {
    margin-top: -595px;

    .inner {
      padding-bottom: 67px;
    }
  }

  .locations {
    .inner {
      background-color: rgba($color-primary, .9);
      border-radius: 10px;
      padding: 20px 30px 30px 30px;
    }

    h2 {
      font-family: $font-serif;
      font-weight: $font-serif-black;
      border-bottom: 1px dotted $color-white;
      color: $color-white;
      font-size: 26px;
      padding-bottom: 5px;
      width: 100%;
    }

    ul {
      margin-left: 20px;
      display: block !important;
      position: relative;
      background: none;
      top: 0;

      li {
        line-height: 22px;
        min-height: 22px;
        width: 100%;
        display: block;

        &:before {
          content: "\f041";
          color: $color-white;
          font-family: "Font Awesome 6 Pro";
          padding-right: 9px;
          margin-left: -20px;
          display: inline-block;
          vertical-align: top;
          position: relative;
          top: 6px;

          .BW & {
            color: $color-black;
          }
        }

        a {
          color: $color-green;
          text-decoration: none;
          font-weight: $font-regular;

          line-height: 22px;
          min-height: 22px;
          padding: 6px 0;
          display: inline-block;

          @include respond-min($md) {
            &:hover {
              color: $color-white;

              &:before{
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }
  }

  .menu {
    .inner {
      background-color: rgba($color-primary, .9);
      border-radius: 10px;
      padding: 20px 30px 30px 30px;
    }

    h2 {
      font-family: $font-serif;
      font-weight: $font-serif-black;
      border-bottom: 1px dotted $color-white;
      color: $color-white;
      font-size: 26px;
      padding-bottom: 5px;
      width: 100%;
    }
    ul {
      column-count: 2;
      display: block !important;
      position: relative;
      background: none;
      top: 0;

      @include respond-max(767px) {
        column-count: 1;
      }

      li {
        line-height: 22px;
        min-height: 22px;
        break-inside: avoid;
        width: 100%;
        display: block;

        a {
          color: $color-green;
          line-height: 22px;
          min-height: 22px;
          padding: 6px 0;
          text-decoration: none;
          font-weight: $font-regular;
          display: inline-block;

          @include respond-min($md) {
            &:hover {
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

/**
 * 1) Block
 */

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
