/**
 * Base/typography stylesheet
 *
 * Table of contents
 * 1) Headings
 * 2) Extras
 * 3) Resets
 */

/**
 * 1) Headings
 */

h1, .t-alpha,
h2, .t-beta,
h3, .t-charlie,
h4, .t-delta {
  display: block;
  margin-top: 0;
  margin-bottom: $baseline;
  line-height: 1.25;
}

h1, .t-alpha {
  font-size: 28px;
  font-weight: $font-serif-black;
  font-family: $font-serif;
}

h2, .t-beta {
  font-size: 24px;
  font-weight: $font-regular;
}

h3, .t-charlie {
  font-size: 22px;
  font-weight: $font-regular;
}

/**
 * 2) Extras
 */

/**
 * 3) Resets
 */

blockquote,
pre,
p,
ul,
ol,
dl,
hr {
  margin-top: 0;
}

blockquote,
pre,
p,
dl,
hr,
table {
  margin-bottom: $baseline;
}

ul > :last-child,
ol > :last-child,
dl > :last-child,
blockquote > :last-child {
  margin-bottom: 0;
}
