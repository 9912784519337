/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.locations {
  .image {
    padding-bottom: 75%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .contact__col {
    background-color: $color-bg;
    padding: 30px;
    border-radius: 10px;

    .BW & {
      background-color: $color-bg !important;
    }

    h2 {
      color: $color-darkgreen;
      font-family: $font-serif;
      font-weight: $font-serif-bold;
      font-size: 20px;
    }

    .fa {
      width: 20px;
      text-align: center;
      font-size: 20px;
      font-family: "Font Awesome 6 Pro";

      &.fa-envelope {
        font-size: 16px;
      }
    }

    @include respond-min($md) {
      a {
        &:hover {
          color: $color-darkgreen;
        }
      }
    }
  }

  .vestiging__col {
    background-color: $color-white;
    padding: 30px;
    border-radius: 10px;

    h2 {
      color: $color-darkgreen;
      font-family: $font-serif;
      font-weight: $font-serif-bold;
      font-size: 20px;
    }

    .fa {
      width: 20px;
      text-align: center;
      font-size: 20px;
      font-family: "Font Awesome 6 Pro";

      &.fa-envelope {
        font-size: 16px;
      }
    }

    @include respond-min($md) {
      a {
        &:hover {
          color: $color-darkgreen;
        }
      }
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
