/**
* Modules/icons stylesheet
*
* Table of contents
* 1) Block
* 2) Element
* 3) Modifier
*/

/**
* 1) Block
*/

// Content colors
.section-content-blue {
  h3, h2, p, a {
    color: $color-primary;

    &.js-toggle-left-navigation, &.js-toggle-left-navigation .fa {
      color: $color-white !important;
    }

    .BW & {
      color: $color-black !important;
    }
  }
}

.section-content-darkblue {
  h3, h2, p, a {
    color: $color-text-dark;

    .BW & {
      color: $color-black !important;
    }
  }
}

.section-content-white {
  h3, h2, p, a {
    color: $color-white;
  }

  b {
    color: $color-green !important;
  }

  h3 {
    font-weight: $font-serif-black;
    font-family: $font-serif;
    margin-bottom: 10px;
  }
}

.section-content-bluewhite {
  h2, p, a {
    color: $color-white;
  }

  h3 {
    font-family: $font-serif;
    font-weight: $font-serif-black;
    font-size: 18px;
    color: $color-text-dark;
    margin-bottom: 5px;
  }
}

//Title colors
.section-title-darkgreen {
  h2 {
    color: $color-darkgreen;

    .BW & {
      color: $color-black;
    }
  }
}

//images
.section-list-image {

  .image__item {
    .image {
      background-size: cover;
      padding-bottom: 60%;
      position: relative;
      display: block;
      border-radius: 20px;
    }
  }

  .video__item {
    border-radius: 20px;

    @include respond-min($md) {
      &:hover {

        .video {
          &:after {
            transition: all .5s ease;
            font-size: 90px;
          }

          &:before {
            background-color: rgba($color-black, .2);
            transition: all .5s ease;
          }
        }
      }
    }

    .video {
      background-size: cover;
      display: block;
      padding-bottom: 90%;
      position: relative;
      border-radius: 20px;

      &:before {
        content: "";
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color-black, .5);
        transition: all .5s ease;
      }

      &:after {
        content: "\f01d";
        font-size: 70px;
        color: #fff;
        font-family: "fontawesome ";
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        transition: all .5s ease;
        text-decoration: none;
      }
    }

  }

  ul, .nav__sub {
    background-color: $color-white;
    border-radius: 10px;
    padding: 30px;
    display: block !important;
    position: relative !important;
    z-index: 0 !important;

    li {
      line-height: 22px;
      min-height: 22px;
      width: 100%;
      display: block;

      a {
        text-decoration: underline;
        color: $color-primary;

        line-height: 22px;
        min-height: 22px;
        padding: 6px 0;
        display: block;

        .BW & {
          color: $color-black !important;

          @include respond-min($md) {
            &:hover {
              color: $color-black;
            }
          }
        }

        @include respond-min($md) {
          &:hover {
            color: $color-darkgreen;
            text-decoration: none;
          }
        }
      }
    }
  }

  h2 {
    font-family: $font-serif;
    font-size: 32px;
    font-weight: $font-serif-black;
  }
}

.section-square-image {

  ul {
    background-color: $color-white;
    border-radius: 10px;
    padding: 30px 30px 30px 40px;
    display: block !important;
    position: relative !important;
    z-index: 0 !important;
    list-style: disc !important;
    color: $color-primary !important;
    margin-bottom: 20px;

    .BW & {
      background-color: $color-white !important;
      color: $color-black !important;
    }
  }

  .image__item {
    .image {
      background-size: cover;
      padding-bottom: 100%;
      position: relative;
      display: block;
      border-radius: 20px;
    }
  }

  h2 {
    font-family: $font-serif;
    font-size: 32px;
    font-weight: $font-serif-black;
  }
}

.section-bottom-image {
  .image__item {
    .image {
      padding-bottom: 90%;
      background-size: contain;
      background-position: center bottom;
      background-repeat: no-repeat;
      position: relative;
      display: block;
      border-radius: 20px;
    }
  }

  ul, .nav__sub {
    background-color: $color-white;
    border-radius: 10px;
    padding: 30px;
    display: block !important;
    position: relative !important;
    z-index: 0 !important;

    li {
      line-height: 22px;
      min-height: 22px;
      width: 100%;
      display: block;

      a {
        text-decoration: underline;
        color: $color-primary;

        line-height: 22px;
        min-height: 22px;
        padding: 6px 0;
        display: block;

        .BW & {
          color: $color-black !important;

          @include respond-min($md) {
            &:hover {
              color: $color-black;
            }
          }
        }

        @include respond-min($md) {
          &:hover {
            color: $color-darkgreen;
            text-decoration: none;
          }
        }
      }
    }
  }

  p {
    font-weight: $font-bold;
  }

  h2 {
    font-family: $font-serif;
    font-size: 32px;
    font-weight: $font-serif-black;
  }

}

// Lists & backgrounds
.section-columns-list {
  ul, .nav__sub {
    column-count: 2 !important;

    @include respond-max(767px) {
      column-count: 1 !important;
    }

    li {
      break-inside: avoid !important;
    }
  }
}

.section__locations {
  ul {

    li {
      line-height: 22px;
      min-height: 22px;
      width: 100%;
      display: inline-block;
      margin-left: 20px;

      a {
        display: inline-block;
      }

      &:before {
        content: "\f041";
        color: $color-darkgreen;
        font-family: "Font Awesome 6 Pro";
        padding-right: 9px;
        margin-left: -20px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 6px;

        .BW & {
          color: $color-black;
        }
      }
    }
  }
}

.section-white-list {
  ul {
    background-color: $color-bg !important;
  }

  .BW & {
    background: $color-black;

    ul {
      background-color: rgba($color-black, .1);
    }

    a {
      color: $color-black !important;

      @include respond-min($md) {
        &:hover {
          color: $color-black;
        }
      }
    }
  }
}

// Section colors
.section__green {
  background-color: $color-green;

  .BW & {
    background: #bebebe;

    ul {
      background-color: $color-white;
    }
  }
}

.section__held {
  background-color: $color-held;

  .BW & {
    background: #bebebe;

    h1, h2, h3, p, a {
      color: $color-black;
    }

    ul {
      background-color: $color-bg;
    }
  }
}

.section__white {
  background-color: $color-white;

  .BW & {
    background-color: $color-white !important;
  }
}

.section__blue {
  background-color: $color-blue;

  .BW & {
    background-color: lighten($color-black, 30%);

    h1, h2, h3, p, a {
      color: $color-white !important;
    }
  }
}

.section__darkblue {
  background-color: $color-primary;

  .BW & {
    background-color: lighten($color-black, 10%);
  }
}

.section__yellow {
  background-color: $color-yellow;

  .BW & {
    background: $color-white !important;
  }
}

.section__darkgreen {
  background-color: $color-darkgreen;

  .BW & {
    background-color: $color-white;

    h1, h2, h3, p, a {
      color: $color-black;
    }

    ul {
      background-color: $color-bg;
    }
  }
}

.section__grey {
  background-color: $color-bg;

  .BW & {
    * {
      color: $color-black !important;
    }
  }
}

.section__turquoise {
  background-color: $color-turquoise;

  .BW & {
    background-color: lighten($color-black, 30%);
  }
}

/**
* 2) Element
*/

.section__skew {
  > .inner {
    overflow: hidden;
    z-index: 1;
    position: relative;

    &:after {
      content: "";
      background-color: $color-green70;
      width: 90%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: -1;
      transform: skewX(30deg);
      margin: 0 auto;

      .BW & {
        background-color: rgba($color-black, .1) !important;
      }

    }
  }
}

/**
* 3) Modifier
*/

.section__skew--reverse {
  > .inner {
    &:after {
      transform: skewX(-30deg) !important;
    }
  }
}

.section__skew--blue {
  > .inner {
    &:after {
      background-color: $color-bluedark;
    }
  }
}

.section__skew--yellow {
  > .inner {
    &:after {
      background-color: $color-yellow70;
    }
  }
}

.section__skew--darkgreen {
  > .inner {
    &:after {
      background-color: $color-darkgreen70;
    }
  }
}

.section__skew--darkgreendarker {
  > .inner {
    &:after {
      background-color: #427d3d;
    }
  }
}

.section__skew--turquoise {
  > .inner {
    &:after {
      background-color: $color-turquoise70;
    }
  }
}

.section__skew--grey {
  > .inner {
    &:after {
      background-color: $color-bg-light;
    }
  }
}

.section__skew--held {
  > .inner {
    &:after {
      background-color: rgba($color-white, .05);
    }
  }
}
