/**
 * Webshop/Basket stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */



/**
 * 1) Block
 */

.basket {}



/**
 * 2) Element
 */

.basket__bar {
    background-color: darken($color-bg, 2%);

    border: 1px solid darken($color-bg, 5%);
}



.basket__content {}



/**
 * 3) Modifier
 */
