/**
* Layout/content stylesheet
*
* Table of contents
* 1) Block
* 2) Element
* 3) Modifier
*/


.content {

  #form_container {

    @include respond-max(480px) {
      table {
        tr {
          td {
            max-width: 90%;
          }
        }
      }
    }

    .BW & {
      color: $color-black !important;

      input[type=text], textarea {
        background-color: $color-white !important;
        color: $color-black !important;
      }
    }
  }

  #sitemapContainer {

    .BW & {
      a {
        color: $color-black !important;
      }
    }

    > ul {
      margin-bottom: 20px;
      list-style: disc;
      font-family: $font-serif;
      margin-left: 20px;

      li {
        a {
          color: $color-darkgreen;
          text-decoration: none;
          font-weight: $font-serif-black;

          @include respond-min($md) {
            &:hover {
              color: $color-primary;
            }
          }
        }
      }

      ul {
        list-style: circle;
        margin-left: 20px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 16px;
        font-family: $font-sans !important;

        li {
          a {
            color: $color-primary !important;
            text-decoration: underline !important;

            @include respond-min($md) {
              &:hover {
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }
  }

  &.content__col, .content__col {
    h1 {
      color: $color-primary;

      .BW & {
        color: $color-black;
      }
    }

    h3 {
      font-weight: $font-serif-heavy;
      font-family: $font-serif;
      font-size: 18px;
      margin-bottom: 5px;
    }

    h2 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 20px !important;
    }
  }

  .content__box {

    img {
      border-radius: 15px;
      margin-bottom: 20px;
    }

    .BW & {
      * {
        color: $color-black;
      }
    }

    h1 {
      span {
        color: $color-green;

        .BW & {
          color: lighten($color-black, 40%) !important;
        }
      }
    }

    h2 {
      font-family: $font-serif;
      font-weight: $font-serif-black;
      margin-bottom: 5px;
    }

    ul {
      margin-bottom: 20px;
      list-style: disc;
      margin-left: 20px;

      .BW & {
        color: $color-black !important;

        li {
          color: $color-black !important;
        }
      }

      li {
        a {
          color: $color-text;
          text-decoration: none;

          .BW & {
            color: $color-black !important;
          }

          @include respond-min($md) {
            &:hover {
              color: $color-text;
            }
          }
        }
      }

      ul {
        list-style: circle;
        margin-left: 20px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 16px;

        li {
          a {
            color: $color-text !important;
            text-decoration: underline !important;

            @include respond-min($md) {
              &:hover {
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }

  }

  .contact__col, .vestiging__col {
    sup, sub {
      position: relative;
      top: 0px;
    }
  }

  &.page__green {
    position: relative;

    .BW & {
      background-color: $color-bg !important;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 27.5px 0 27.5px;
      border-color: $color-green70 transparent transparent transparent;
      position: absolute;
      bottom: -30px;
      margin: 0 auto;
      z-index: 2;
      right: 0;
      left: 0;

      .BW & {
        border-color: $color-bg transparent transparent transparent;
      }
    }

    .vestiging__col {
      background-color: $color-white;
      padding: 50px 30px 30px 30px;
      border-radius: 10px;

      sup, sub {
        position: relative;
        top: 0px;
      }

      img.line {
        margin-bottom: 20px;
        padding-bottom: 50px;
        border-bottom: dotted 1px $color-darkgreen;
      }

      h2 {
        color: $color-darkgreen;
        font-family: $font-serif;
        font-weight: $font-serif-bold;
        font-size: 20px;
      }

      .fa {
        font-family: "Font Awesome 6 Pro";
      }

      @include respond-min($md) {
        a {
          &:hover {
            color: $color-darkgreen;
          }
        }
      }

    }
  }

  &.white {
    background-color: $color-white !important;

    .vacancy__col {
      background-color: $color-bg !important;
    }

    .boxed {

      &.text {
        .inner {
          background-color: $color-bg;
          padding: 30px 30px 20px 30px;
          border-radius: 10px;
        }
      }

      .inner {
        background-color: $color-bg;
        padding: 10px 15px;
        border-radius: 10px;
      }

      &.blue {
        .inner {
          background-color: $color-text-dark;

          .BW & {
            background-color: $color-bg !important;

            * {
              color: $color-white !important;
            }
          }

          li {
            color: $color-white;
            float: left;

            .BW & {
              color: $color-white !important;
            }
          }

          .BW & {
            background-color: $color-black !important;
          }
        }
      }

      &.list {
        ul {
          display: flex;
          margin-bottom: 0;
          list-style: none;
          margin-left: 0;

          @include respond-max(767px) {
            display: inline-block;
          }

          li {
            font-size: 18px;
            padding-left: 25px;
            margin-right: 30px;

            .fa {
              color: $color-green;
              margin-left: -25px;
              width: 25px;
              font-size: 20px;

              .BW & {
                color: $color-white !important;
              }
            }
          }
        }

      }
    }

    .quote {
      background-color: $color-bg;
      padding: 30px;
      border-radius: 10px;
      clear: both;
      display: inline-block;

      .image {
        border-bottom: 1px dotted $color-text-dark;
        width: 100%;
        height: 300px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: -70%;

        @include respond-between($xs, 991px) {
          margin-top: -150px;
        }
        @include respond-max(767px) {
          margin-top: -120px;
        }
      }

      h2, h2 * {
        color: $color-text-dark;
        font-size: 26px;
      }

      h3 {
        color: $color-green;
        font-weight: 200;
      }
    }

    .questions__col {
      border-radius: 10px;
      margin-top: 30px;
      background-color: $color-primary;

      .image__col {
        margin: 0 auto;
        width: 70%;

        .image {
          border-radius: 100%;
          background-size: cover;
          padding-bottom: 100%;
        }
      }

      a {
        color: $color-white !important;
        text-decoration: none;

        &:hover {
          color: $color-green;

          .BW & {
            color: $color-white !important;
            text-decoration: underline;
          }
        }
      }

      .BW & {
        background-color: $color-black !important;
      }

      * {
        color: $color-white;
      }

      ul {
        margin-bottom: 0;
        list-style: none;
        margin-left: 0;

        li {
          font-size: 18px;
          padding-left: 25px;

          .fa {
            color: $color-green;
            text-align: center;
            margin-left: -40px;
            font-size: 18px;
            width: 40px;

            .BW & {
              color: $color-white !important;
            }
          }
        }
      }

      .call {
        padding-top: 20px;
        display: block;
        clear: both;
        text-decoration: none;
        margin-left: 20px;
        position: relative;
        margin-bottom: 0px;

        &:before {
          content: "\f095";
          color: $color-green;
          font-family: "Font Awesome 6 Pro";
          padding-right: 9px;
          margin-left: -20px;
          display: inline-block;
          vertical-align: top;
          position: relative;
          top: 0px;

          .BW & {
            color: $color-white !important;
          }
        }

        .BW & {
          color: $color-white !important;
        }

      }

      h2 {
        font-family: $font-serif;
        color: $color-green;
        font-size: 26px;
        font-weight: $font-serif-black;
        padding: 0;
        margin-bottom: 20px;
        padding-bottom: 0;
        border-bottom: 1px dotted $color-darkgreen;

        .BW & {
          color: $color-white !important;
          border-color: $color-white !important;
        }
      }

      .inner {
        padding: 30px;
      }
    }

  }

}

.color__col {
  border-radius: 10px;
  margin-top: 30px;
  background-color: $color-primary;

  .BW & {
    background-color: $color-black !important;
  }

  * {
    color: $color-white;
  }

  ul {
    clear: both;
    margin-left: 20px;
    display: block !important;
    position: relative;
    background: none;
    top: 0;

    li {
      &:before {
        content: "\f007";
        color: $color-green;
        font-family: "Font Awesome 6 Pro";
        padding-right: 9px;
        margin-left: -20px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 0px;

        .BW & {
          color: $color-white !important;
        }
      }
    }
  }

  .call {
    padding-top: 20px;
    display: block;
    clear: both;
    text-decoration: none;
    margin-left: 20px;
    position: relative;
    margin-bottom: 0px;

    &:before {
      content: "\f095";
      color: $color-green;
      font-family: "Font Awesome 6 Pro";
      padding-right: 9px;
      margin-left: -20px;
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: 0px;

      .BW & {
        color: $color-white !important;
      }
    }

    .BW & {
      color: $color-white !important;
    }

  }

  h2 {
    font-family: $font-serif;
    color: $color-green;
    font-size: 26px;
    font-weight: $font-serif-black;
    padding: 0;
    margin-bottom: 20px;
    padding-bottom: 0;
    border-bottom: 1px dotted $color-darkgreen;

    .BW & {
      color: $color-white !important;
      border-color: $color-white !important;
    }
  }

  .inner {
    padding: 30px;
  }
}

.vacancy__col {
  background-color: $color-white;
  padding: 30px !important;
  border-radius: 10px;

  .BW & {
    background-color: $color-white !important;
  }

  ul {
    li {
      padding: 10px 0;

      &.selected {
        a {
          font-weight: $font-bold;
          color: $color-darkgreen;
          text-decoration: none;
        }
      }
    }
  }

  h2 {
    color: $color-darkgreen;
    font-family: $font-serif;
    font-weight: $font-serif-bold;
    font-size: 26px !important;
    text-shadow: none !important;
    position: relative;
    top: 0 !important;
    padding: 0;
    margin-bottom: 20px;
    padding-bottom: 0;
    border-bottom: 1px dotted $color-darkgreen;

    .BW & {
      border-color: lighten($color-black, 20%) !important;
    }
  }

  .fa {
    width: 20px;
    text-align: center;
    font-size: 20px;
    font-family: "Font Awesome 6 Pro";

    &.fa-envelope {
      font-size: 16px;
    }
  }

  @include respond-min($md) {
    a {
      &:hover {
        color: $color-darkgreen;
      }
    }
  }
}

.vestiging__col {
  background-color: $color-white;
  padding: 30px !important;
  border-radius: 10px;
  margin-bottom: 20px;

  .BW & {
    background-color: $color-bg !important;
  }

  h2 {
    color: $color-darkgreen;
    font-family: $font-serif;
    font-weight: $font-serif-bold;
    font-size: 26px !important;
    text-shadow: none !important;
    position: relative;
    top: 0 !important;
    padding: 0;
    margin-bottom: 20px;
    padding-bottom: 0;
    border-bottom: 1px dotted $color-darkgreen;

    .BW & {
      border-bottom: 1px dotted $color-black;
    }
  }

  .fa {
    width: 20px;
    text-align: center;
    font-size: 20px;
    font-family: "Font Awesome 6 Pro";

    &.fa-envelope {
      font-size: 16px;
    }
  }

  @include respond-min($md) {
    a {
      &:hover {
        color: $color-darkgreen;
      }
    }
  }
}

.left__col {
  position: relative;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  z-index: 0;
  display: block;
  clear: both;

  .BW & {
    nav {
      background-color: $color-white;

      h2 {
        border-color: $color-black;
      }

      ul {
        background-color: $color-white;

        .nav__item {
          .nav__link {
            @include respond-min($md) {
              &:hover {
                color: $color-black;
              }
            }
          }
        }
      }

    }
  }

  &.active {
    opacity: 1;
    height: auto;
    overflow: visible;
    margin-bottom: 20px;
    transition: opacity 0.3s ease-out;
  }

  @include respond-min($md) {
    opacity: 1;
    height: auto;
    overflow: visible;
    transition: opacity 0.3s ease-out;
  }
}

.js-toggle-left-navigation {
  width: auto !important;
  clear: both;
  margin-bottom: 20px;

  .fa {
    float: right;
    line-height: 50px;
    margin-left: 10px;
  }
}


.newvacancies {



  &.header--small {
    .nav {
      background: red !important;
      top: auto !important;
      bottom: -409px;

      .nav__primary {
        border-radius: 10px 10px 0 0;
      }
    }
  }
  &.small--menu {
    .nav {
      background: none;
      top: auto !important;
      bottom: -409px;

      .nav__primary {
        border-radius: 10px 10px 0 0;
      }
    }
  }
  .darkblue-detail {
    padding-top: 110px;
    position: relative;

    .melding {
      @include respond-between($sm, $md) {
        margin-top: -80px;
        margin-bottom: 80px;
      }
      @include respond-min($md) {
        margin-top: -110px;
        margin-bottom: 110px;
      }
    }

    .content {
      position: relative;

      .content__box {
        .inner {
          h1:first-child {
            margin-top: 28px;
          }
        }
      }

      .vestiging__col {
        background-color: $color-white;
        padding: 50px 30px 30px 30px;
        border-radius: 10px;

        &.contact {
          h2 {
            color: $color-darkgreen;
            font-family: $font-serif;
            font-weight: $font-serif-bold;
            font-size: 26px;
            text-shadow: none !important;
            position: relative;
            top: 0 !important;
            padding: 0px;
            margin-bottom: 20px;
            padding-bottom: 0px;
            border-bottom: dotted 1px $color-darkgreen;
          }
        }

        p {
          margin-bottom: 10px;
        }

        .fa {
          width: 20px;
          text-align: center;
          font-size: 20px;
          font-family: "Font Awesome 6 Pro";

          &.fa-envelope {
            font-size: 16px;
          }
        }

        img.line {
          margin-bottom: 20px;
          padding-bottom: 50px;
          border-bottom: dotted 1px $color-darkgreen;
        }

        h2 {
          color: $color-darkgreen;
          font-family: $font-serif;
          font-weight: $font-serif-bold;
          font-size: 20px;
        }

        @include respond-min($md) {
          a {
            &:hover {
              color: $color-darkgreen;
            }
          }
        }

      }
    }

    .gradient {
      height: 520px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#425462+1,7a8791+50,425462+100 */
      background: rgb(66, 84, 98); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(66, 84, 98, 1) 1%, rgba(122, 135, 145, 1) 50%, rgba(66, 84, 98, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(66, 84, 98, 1) 1%, rgba(122, 135, 145, 1) 50%, rgba(66, 84, 98, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(66, 84, 98, 1) 1%, rgba(122, 135, 145, 1) 50%, rgba(66, 84, 98, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#425462', endColorstr='#425462', GradientType=0); /* IE6-9 */
    }

    > .content {
      background-color: $color-text-dark30;
      color: $color-primary;

      .BW & {
        color: $color-black !important;

        ul {
          color: $color-black !important;

          li {
            color: $color-black !important;
          }
        }
      }

      h3 {
        font-weight: $font-serif-heavy;
        font-family: $font-serif;
        font-size: 18px;
        margin-bottom: 5px;

        .BW & {
          color: $color-white;
        }
      }
    }
  }
}

/**
* 1) Block
*/

/**
* 2) Element
*/

/**
* 3) Modifier
*/
