/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

.faq {
  background-color: $color-bg;

  .content__box {
    column-count: 2;

    @include respond-max(768px) {
      column-count: 1;
    }
  }

  &.faq__page {
    background: none !important;

    .BW & {
      .toggle, .open-toggle {
        background-color: $color-bg !important;
      }
    }
  }

  .BW & {
    background-color: lighten($color-black, 10%);
    color: $color-white;

    h2 {
      color: $color-white;
    }

    .toggle-box {
      &.active {
        .toggle {
          background-color: $color-white;
        }

        .open-toggle {
          color: $color-black !important;
          background-color: $color-white;
        }
      }
    }

    .toggle {
      color: $color-black !important;
      background: $color-white !important;
    }

    .open-toggle {
      color: $color-black !important;
      background-color: $color-white !important;

      p, a {
        color: $color-black !important;
      }
    }
  }

  h2 {
    color: $color-primary;
    font-weight: $font-serif-black;
    font-family: $font-serif;
    font-size: 32px;
    margin-bottom: 40px;
  }

  .toggle-box {
    display: inline-block;
    column-fill: auto;
    width: 100%;
    page-break-inside: avoid;

    p, a {
      color: $color-primary !important;
      font-size: 17px !important;
    }

    &.active {
      .toggle {
        background: darken($color-bg, 5%);

        &:after {
          content: "\f0d8";
          float: right;
          font-family: "Font Awesome 6 Pro";
          font-size: 18px;
        }
      }
    }

    .toggle {
      span {
        width: 90%;
        display: inline-block;
      }

      cursor: pointer;
      background: $color-white;
      color: $color-primary;
      width: 100%;
      margin-bottom: 0 !important;
      padding: 20px 20px 20px 20px;
      font-size: 18px;
      font-family: $font-sans !important;
      font-weight: $font-bold;
      transition: all .3s ease;

      &:after {
        content: "\f0d7";
        float: right;
        font-family: "Font Awesome 6 Pro";
        font-size: 18px;
        color: #7a8891;

        .BW & {
          color: $color-black !important;
        }
      }
    }

    .open-toggle {
      width: 100%;
      padding: 0px 20px 20px 20px;
      background: darken($color-bg, 5%);
    }
  }
}

/**
 * 1) Block
 */

.questions {
  position: relative;

  &.vacancy {

    .tab-content {
      &.current {
        padding-top: 0px;
      }
    }

    @include respond-max(767px) {
      > .tabs-menu {
        position: relative;

        .held & {
          padding-top: 2px !important;

          @include respond-max(480px) {
            padding-top: 30px !important;
          }
        }

        li {
          &.current {
            background: $color-white !important;
            border: solid 2px $color-bg !important;
            color: $color-green !important;
            height: 52px !important;

            .BW & {
              background: $color-white !important;
              color: $color-black !important;
            }
          }
        }
      }
    }
  }
}

.tab {
  @include respond-min($md) {
    .tabs-menu {
      li {
        display: block;
        color: #444 !important;
        padding: 0;
        text-decoration: none;
        width: 100%;
        line-height: 40px;
        height: 40px;
        font-weight: 800 !important;
        text-transform: uppercase !important;
      }
    }
  }

  .tab-content {
    display: none;

    .article_promo {
      overflow: hidden;
    }

    &.current {
      display: inherit;
      padding: 50px 0;
    }

  }
}

.tabs-menu {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  margin: 0 auto !important;
  z-index: 20;
  width: 890px;
  display: block;
  float: none;

  @include respond-max(768px) {
    width: 100%;
  }

  @include respond-between(768px, 991px) {
    width: 100%;
    text-align: center;

    .held & {
      bottom: 100%;
    }

    li {
      width: 30% !important;
      font-size: 20px !important;
    }

    .held & {
      li {
        width: calc(50% - 40px) !important;
        font-size: 20px !important;
        display: inline-block;
        margin-right: 15px !important;
        border-radius: 10px 10px 0 0;
        margin-bottom: 0 !important;
      }
    }
  }

  @include respond-between(480px, 767px) {
    .held & {
      bottom: 100%;
      position: absolute !important;

      li {
        width: calc(50% - 10px) !important;
        display: inline-block;
        margin-right: 5px !important;
        margin-left: 5px !important;
        font-size: 16px !important;
        border-radius: 10px 10px 0 0;
        margin-bottom: 0 !important;
      }
    }
  }

  .menu-is-active &, .header--small & {
    z-index: 1 !important;
  }

  @include respond-max(767px) {
    position: absolute;
    background-color: none;
    padding: 30px 10px 30px 10px;
  }

  @include respond-max(768px) {
    position: absolute;
    background-color: none;

    li {
      background: $color-secondary !important;
      color: $color-white;
      line-height: 50px !important;
      height: 50px !important;

      .BW & {
        background: lighten($color-black, 20%) !important;
        color: $color-white !important;
      }

      &.current {
        background: $color-white !important;
        color: $color-secondary !important;

        &:after {
          color: $color-green;
        }

        .BW & {
          background: $color-white;
          color: $color-black;

          &:after {
            color: $color-black;
          }
        }
      }
    }
  }

  li {
    cursor: pointer;
    display: inline-block;
    color: $color-white;
    padding: 0 20px;
    text-decoration: none;
    line-height: 60px;
    height: 60px;
    width: 430px;
    margin-right: 20px;
    background-color: $color-secondary;
    text-align: left;
    border-radius: 10px 10px 0 0;
    font-family: $font-serif;
    font-weight: $font-serif-black;
    font-size: 26px;
    position: relative;

    &:after {
      content: "\f061";
      font-family: "Font Awesome 5 Pro", "FontAwesome";
      font-weight: 300;
      color: $color-white;
      right: 15px;
      position: absolute;
      top: 2px;
      padding-right: 20px;
    }

    a {
      display: block;
      text-decoration: none;
    }

    .BW & {
      background: lighten($color-black, 80%);
      color: $color-black;
    }

    @include respond-min($md) {
      &:hover {
        background-color: $color-white;
        color: $color-secondary;

        &:after {
          color: $color-green;
        }

        .BW & {
          background-color: lighten($color-black, 25%);
          color: $color-white !important;

          &:after {
            color: $color-white;
          }
        }
      }
    }

    @include respond-max($md) {
      font-size: 20px;
      width: auto;
      padding: 0 20px;
      margin-right: 10px;
      background-color: $color-green;
    }

    @include respond-max(991px) {
      font-size: 20px;
      width: 90% !important;
      padding: 0 10px;
      margin-right: 0px;
      display: block;
      border-radius: 10px;
      margin: 0 auto !important;
      margin-bottom: 10px !important;
    }

    &:last-child {
      margin-right: 0;
    }

    &.current {
      color: $color-secondary;
      background-color: $color-white;

      &:after {
        color: $color-green;
      }

      .BW & {
        background: $color-white;
        color: $color-black;

        &:after {
          color: $color-black;
        }
      }
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
