/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

body {

  &.BW {
    background-color: lighten($color-black, 60%) !important;

    .gradient {
      background: none !important;
    }

    &.colors {
      background-color: $color-bg;

      img.line {
        border-color: $color-black !important;
      }

      .vestiging__col {
        background-color: $color-white !important;

        h2 {
          color: $color-black !important;
        }

        &.contact {
          h2 {
            border-color: $color-black !important;
          }
        }

      }

      > .content {
        background-color: $color-bg;

        h1, h2, h3, h4, p, a, h1 a, h2 a, h3 a, h4 a, p {
          color: $color-black;
        }
      }

    }

    .BW .hero__item, .slider, .readspeaker, img, .hero__item, .lity-container, .image, .video, .work, .image__footer, .procedure {
      filter: grayscale(100%) !important;
      -webkit-filter: grayscale(100%) !important;
      -moz-filter: grayscale(100%) !important;
      -o-filter: grayscale(100%) !important;
      -ms-filter: grayscale(100%) !important;
    }

    .logo-left-zw {
      display: block !important;
    }

    .logo-left-color {
      display: none !important;
    }

    .logo-zw {
      display: block !important;
    }

    .logo-color {
      display: none !important;
    }
  }

  &.colors {

    .melding {

      @include respond-between($sm, $md) {
        margin-top: -80px;
        margin-bottom: 80px;
      }
      @include respond-min($md) {
        margin-top: -110px;
        margin-bottom: 110px;
      }
    }

    .content {
      position: relative;

      .content__box {
        .inner {
          h1:first-child {
            margin-top: 28px;
          }
        }
      }

      .vestiging__col {
        background-color: $color-white;
        padding: 50px 30px 30px 30px;
        border-radius: 10px;

        &.contact {
          h2 {
            color: $color-darkgreen;
            font-family: $font-serif;
            font-weight: $font-serif-bold;
            font-size: 26px;
            text-shadow: none !important;
            position: relative;
            top: 0 !important;
            padding: 0px;
            margin-bottom: 20px;
            padding-bottom: 0px;
            border-bottom: dotted 1px $color-darkgreen;
          }
        }

        p {
          margin-bottom: 10px;
        }

        .fa {
          width: 20px;
          text-align: center;
          font-size: 20px;
          font-family: "Font Awesome 6 Pro";

          &.fa-envelope {
            font-size: 16px;
          }
        }

        img.line {
          margin-bottom: 20px;
          padding-bottom: 50px;
          border-bottom: dotted 1px $color-darkgreen;
        }

        h2 {
          color: $color-darkgreen;
          font-family: $font-serif;
          font-weight: $font-serif-bold;
          font-size: 20px;
        }

        @include respond-min($md) {
          a {
            &:hover {
              color: $color-darkgreen;
            }
          }
        }

      }
    }

  }

  &.green {
    background-color: $color-green;

    > .content {
      background-color: $color-green70;
      color: $color-text-dark;

      h3 {
        font-weight: $font-serif-heavy;
        font-family: $font-serif;
        font-size: 18px;
        margin-bottom: 5px;
        color: $color-text-dark;

        .BW & {
          color: $color-black;
        }
      }
    }

    .nav__secondary .contrast a, .nav__secondary .listen a {
      color: $color-primary;

      .BW & {
        color: $color-black;
      }
    }
  }

  &.darkblue {
    background-color: $color-primary;

    > .content {
      background-color: $color-text-dark30;
      color: $color-primary;

      h3 {
        font-weight: $font-serif-heavy;
        font-family: $font-serif;
        font-size: 18px;
        margin-bottom: 5px;

        .BW & {
          color: $color-white;
        }
      }
    }
  }

  &.darkgreen {
    background-color: $color-darkgreen;

    > .content {
      background-color: $color-darkgreen30;
      color: $color-primary;

      h3 {
        font-weight: $font-serif-heavy;
        font-family: $font-serif;
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }

  &.grey {
    background-color: $color-bg;

    > .content {
      background-color: $color-bg;
      color: $color-primary;

      .BW & {
        color: $color-black !important;
      }
    }
  }

  &.yellow {
    background-color: $color-yellow;

    .nav__secondary .contrast a, .nav__secondary .listen a {
      color: $color-primary;
    }

    > .content {
      background-color: $color-yellow70;
      color: $color-primary;

      .BW & {
        color: $color-black !important;
      }
    }
  }

  &.turquoise {
    background-color: $color-turquoise;

    .nav__secondary .contrast a, .nav__secondary .listen a {
      color: $color-primary;
    }

    > .content {
      background-color: $color-turquoise30;
      color: $color-primary;
    }
  }

  &.blue {
    background-color: $color-blue;

    .nav__secondary .contrast a, .nav__secondary .listen a {
      color: $color-primary;
    }

    > .content {
      background-color: $color-blue30;
      color: $color-primary;
    }
  }

  &.blue-gradient {
    background-color: $color-blue;

    .gradient {
      height: 520px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#425462+1,7a8791+50,425462+100 */
      background: $color-blue; /* Old browsers */
      background: -moz-linear-gradient(top, rgba($color-blue, 1) 1%, rgba($color-blue30, 1) 50%, rgba($color-blue, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba($color-blue, 1) 1%, rgba($color-blue30, 1) 50%, rgba($color-blue, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba($color-blue, 1) 1%, rgba($color-blue30, 1) 50%, rgba($color-blue, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-blue', endColorstr='$color-blue', GradientType=0); /* IE6-9 */
    }

    .nav__secondary .contrast a, .nav__secondary .listen a {
      color: $color-primary;
    }

    > .content {
      background-color: $color-blue30;
      color: $color-primary;
    }
  }

  &.turquoise-gradient {
    background-color: $color-turquoise;

    .nav__secondary .contrast a, .nav__secondary .listen a {
      color: $color-primary;
    }

    .gradient {
      height: 520px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#425462+1,7a8791+50,425462+100 */
      background: $color-turquoise; /* Old browsers */
      background: -moz-linear-gradient(top, rgba($color-turquoise, 1) 1%, rgba($color-turquoise30, 1) 50%, rgba($color-turquoise, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba($color-turquoise, 1) 1%, rgba($color-turquoise30, 1) 50%, rgba($color-turquoise, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba($color-turquoise, 1) 1%, rgba($color-turquoise30, 1) 50%, rgba($color-turquoise, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-turquoise', endColorstr='$color-turquoise', GradientType=0); /* IE6-9 */
    }

    > .content {
      background-color: $color-turquoise30;
      color: $color-primary;
    }
  }

  &.darkblue-gradient {
    background-color: $color-primary;

    .gradient {
      height: 520px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#425462+1,7a8791+50,425462+100 */
      background: rgb(66, 84, 98); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(66, 84, 98, 1) 1%, rgba(122, 135, 145, 1) 50%, rgba(66, 84, 98, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(66, 84, 98, 1) 1%, rgba(122, 135, 145, 1) 50%, rgba(66, 84, 98, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(66, 84, 98, 1) 1%, rgba(122, 135, 145, 1) 50%, rgba(66, 84, 98, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#425462', endColorstr='#425462', GradientType=0); /* IE6-9 */
    }

    > .content {
      background-color: $color-text-dark30;
      color: $color-primary;

      .BW & {
        color: $color-black !important;

        ul {
          color: $color-black !important;

          li {
            color: $color-black !important;
          }
        }
      }

      h3 {
        font-weight: $font-serif-heavy;
        font-family: $font-serif;
        font-size: 18px;
        margin-bottom: 5px;

        .BW & {
          color: $color-white;
        }
      }
    }
  }

  &.yellow-gradient {
    background-color: $color-yellow;

    .nav__secondary .contrast a, .nav__secondary .listen a {
      color: $color-primary;
    }

    .gradient {
      height: 520px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#425462+1,7a8791+50,425462+100 */
      background: $color-yellow; /* Old browsers */
      background: -moz-linear-gradient(top, rgba($color-yellow, 1) 1%, rgba($color-yellow30, 1) 50%, rgba($color-yellow, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba($color-yellow, 1) 1%, rgba($color-yellow30, 1) 50%, rgba($color-yellow, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba($color-yellow, 1) 1%, rgba($color-yellow30, 1) 50%, rgba($color-yellow, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-yellow', endColorstr='$color-yellow', GradientType=0); /* IE6-9 */
    }

    > .content {
      background-color: $color-yellow70;
      color: $color-primary;

      .BW & {
        color: $color-black !important;
      }
    }
  }

  &.green-gradient {
    background-color: $color-green;

    h1 {
      span {
        color: $color-darkgreen !important;
      }
    }

    .gradient {
      height: 520px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#425462+1,7a8791+50,425462+100 */
      background: $color-green; /* Old browsers */
      background: -moz-linear-gradient(top, rgba($color-green, 1) 1%, rgba($color-green30, 1) 50%, rgba($color-green, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba($color-green, 1) 1%, rgba($color-green30, 1) 50%, rgba($color-green, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba($color-green, 1) 1%, rgba($color-green30, 1) 50%, rgba($color-green, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-green', endColorstr='$color-green', GradientType=0); /* IE6-9 */
    }

    > .content {
      background-color: $color-green70;
      color: $color-text-dark;

      h3 {
        font-weight: $font-serif-heavy;
        font-family: $font-serif;
        font-size: 18px;
        margin-bottom: 5px;
        color: $color-text-dark;

        .BW & {
          color: $color-black;
        }
      }
    }

    .nav__secondary .contrast a, .nav__secondary .listen a {
      color: $color-primary;

      .BW & {
        color: $color-black;
      }
    }
  }

  &.darkgreen-gradient {
    background-color: $color-darkgreen;

    .gradient {
      height: 520px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#425462+1,7a8791+50,425462+100 */
      background: $color-darkgreen; /* Old browsers */
      background: -moz-linear-gradient(top, rgba($color-darkgreen, 1) 1%, rgba($color-darkgreen70, 1) 50%, rgba($color-darkgreen, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba($color-darkgreen, 1) 1%, rgba($color-darkgreen70, 1) 50%, rgba($color-darkgreen, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba($color-darkgreen, 1) 1%, rgba($color-darkgreen70, 1) 50%, rgba($color-darkgreen, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-darkgreen', endColorstr='$color-darkgreen', GradientType=0); /* IE6-9 */
    }

    > .content {
      background-color: $color-darkgreen30;
      color: $color-primary;

      h3 {
        font-weight: $font-serif-heavy;
        font-family: $font-serif;
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }

}

/**
 * 2) Element
 */

.content__arrow-blue {
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 27.5px 0 27.5px;
    border-color: $color-text-dark30 transparent transparent transparent;
    position: absolute;
    bottom: -30px;
    margin: 0 auto;
    z-index: 2;
    right: 0;
    left: 0;

    .BW & {
      border-color: $color-bg transparent transparent transparent;
    }
  }
}

.content__arrow-green {
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 27.5px 0 27.5px;
    border-color: $color-green70 transparent transparent transparent;
    position: absolute;
    bottom: -30px;
    margin: 0 auto;
    z-index: 2;
    right: 0;
    left: 0;

    .BW & {
      border-color: $color-bg transparent transparent transparent;
    }
  }
}

.content__arrow-darkgreen {
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 27.5px 0 27.5px;
    border-color: $color-darkgreen30 transparent transparent transparent;
    position: absolute;
    bottom: -30px;
    margin: 0 auto;
    z-index: 2;
    right: 0;
    left: 0;

    .BW & {
      border-color: $color-bg transparent transparent transparent;
    }
  }
}

.content__arrow-lightblue {
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 27.5px 0 27.5px;
    border-color: $color-blue30 transparent transparent transparent;
    position: absolute;
    bottom: -30px;
    margin: 0 auto;
    z-index: 2;
    right: 0;
    left: 0;

    .BW & {
      border-color: $color-bg transparent transparent transparent;
    }
  }
}

.content__arrow-turquoise {
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 27.5px 0 27.5px;
    border-color: $color-turquoise30 transparent transparent transparent;
    position: absolute;
    bottom: -30px;
    margin: 0 auto;
    z-index: 2;
    right: 0;
    left: 0;

    .BW & {
      border-color: $color-bg transparent transparent transparent;
    }
  }
}

.content__arrow-yellow {
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 27.5px 0 27.5px;
    border-color: $color-yellow70 transparent transparent transparent;
    position: absolute;
    bottom: -30px;
    margin: 0 auto;
    z-index: 2;
    right: 0;
    left: 0;

    .BW & {
      border-color: $color-bg transparent transparent transparent;
    }
  }
}

/**
 * 3) Modifier
 */


