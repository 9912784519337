/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.vacancy__item {
  text-decoration: none;
  margin-bottom: 30px;
  color: $color-primary;

  @include respond-max(767px) {
    font-size: 15px !important;
  }

  .inner {
    background-color: $color-bg;
    padding: 20px 20px 40px 20px;
    border-radius: 10px;
  }

  @include respond-min($md) {
    &:hover {
      .inner {
        background-color: $color-primary !important;

        .button{
          .BW & {
            color: $color-black !important;
            background: $color-white !important;
          }
        }

        h3{
          .BW & {
            color: $color-white !important;
          }
        }

        .BW & {
          background-color: $color-black !important;
          color: $color-white !important;

          i {
            color: $color-white !important;
          }
        }
      }

      .specs {
        background-color: rgba($color-white, .2) !important;
      }

      *:not(.button) {
        color: $color-white;
      }

      .button {
        background: $color-green !important;
      }
    }
  }

  .button {
    margin-top: -20px;
    position: absolute;
    z-index: 1;
    left: 45px;

    @include respond-max(767px) {
      font-size: 14px !important;
      padding: 0 10px !important;
    }
  }

  h3 {
    font-weight: $font-bold;
    font-family: $font-serif;
    font-size: 18px !important;

    .BW & {
      color: $color-black;
    }

    @include respond-max(767px) {
      font-size: 16px !important;
    }
  }

  .specs {
    background-color: $color-bg-light;
    padding: 15px 15px 30px 15px;
    border-radius: 10px;

    .BW & {
      color: $color-black !important;
    }

    @include respond-max(767px) {
      font-size: 14px !important;
    }

    ul {
      li {
        padding-left: 35px;

        i {
          color: $color-green;
          width: 35px;
          margin-left: -35px;
          text-align: center;
          display: inline-block;

          .BW & {
            color: $color-black !important;
          }
        }
      }
    }

  }

  &:nth-child(odd) {
    .inner {
      background-color: $color-bg-light;
    }

    .specs {
      background-color: $color-bg;
    }
  }
}


.work {
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  position: relative;

  .BW & {
    .button {
      background-color: $color-white !important;
      color: $color-black !important;

      @include respond-min($md) {
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }

  @include respond-max(991px) {
    &:after {
      content: "";
      background-color: rgba($color-black, 0.5);
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .content {
    z-index: 1;
    position: relative;
  }

  h2 {
    color: $color-white;
    font-family: $font-serif;
    font-weight: $font-serif-black;
    font-size: 32px;
  }

  p {
    color: $color-white;
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
