/**
 * Modules/buttons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.btn, button {
  display: inline-block;
  padding: $baseline/4 $baseline/2;
  background: none;
  border: none;
  text-decoration: none;
  outline: none;
  position: relative;
}

button:not([class]), input[type=button], input[type=reset], input[type=submit], .submit, #bBasket .button {
  font-weight: $font-regular;
  display: inline-block;
  padding: $baseline/4 $baseline/2;
  background: none;
  border: none;
  text-decoration: none;
  position: relative;
  background-color: $color-darkgreen;
  color: $color-white;
  border-radius: 10px;
  padding: 0 40px;
  min-height: 50px;
  line-height: 50px;

  .BW & {
    background-color: $color-black !important;
    color: $color-white !important;

    @include respond-min($md) {
      &:hover {
        background-color: $color-black !important;
        color: $color-white !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-white;
      color: $color-darkgreen;
    }
  }
}

.button {
  font-weight: $font-regular;
  display: inline-block;
  padding: $baseline/4 $baseline/2;
  background: none;
  border: none;
  text-decoration: none;
  position: relative;
  background-color: $color-green;
  color: $color-white;
  border-radius: 10px;
  padding: 0 40px;
  min-height: 50px;
  line-height: 50px;

  .BW & {
    background-color: $color-black !important;
    color: $color-white !important;

    @include respond-min($md) {
      &:hover {
        background-color: $color-white !important;
        color: $color-black !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-white;
      color: $color-green;
    }
  }

  &.small{
    min-height: 40px !important;
    line-height: 40px !important;
    font-size: 16px !important;
  }
}

.button.green {
  font-weight: $font-regular;
  display: inline-block;
  padding: $baseline/4 $baseline/2;
  background: none;
  border: none;
  text-decoration: none;
  position: relative;
  background-color: $color-green;
  color: $color-white;
  border-radius: 10px;
  padding: 0 40px;
  min-height: 50px;
  line-height: 50px;

  .BW & {
    background-color: $color-black !important;
    color: $color-white !important;

    @include respond-min($md) {
      &:hover {
        background-color: $color-white !important;
        color: $color-black !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-darkgreen;
      color: $color-white;
    }
  }
}

.button.lines {
  font-weight: $font-regular;
  display: inline-block;
  padding: $baseline/4 $baseline/2;
  background: none;
  border: 1px solid $color-white;
  text-decoration: none;
  position: relative;
  background-color: rgba($color-black, 0.4);
  color: $color-green;
  border-radius: 10px;
  padding: 0 30px;
  min-height: 40px;
  line-height: 40px;
  font-size: 16px;

  .BW & {
    background-color: rgba($color-black, 0.4);
    color: $color-white !important;

    @include respond-min($md) {
      &:hover {
        background-color: $color-white !important;
        color: $color-black !important;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      color: $color-white;
    }
  }
}

.button.blue {
  background-color: $color-primary;
  color: $color-white;

  .BW & {
    background-color: $color-black !important;
    color: $color-white !important;

    @include respond-min($md) {
      &:hover {
        background-color: $color-white !important;
        color: $color-black !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-green;
      color: $color-white;
    }
  }
}

.button.whitegreen {
  background-color: $color-white;
  color: $color-primary;

  .BW & {
    background-color: $color-white !important;
    color: $color-black !important;

    @include respond-min($md) {
      &:hover {
        background-color: $color-white !important;
        color: $color-black !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-green;
      color: $color-white;
    }
  }
}

.button.whiteturquoise {
  background-color: $color-white;
  color: $color-turquoise;

  .BW & {
    background-color: $color-white !important;
    color: $color-black !important;

    @include respond-min($md) {
      &:hover {
        background-color: $color-white !important;
        color: $color-black !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.button.whitedarkgreen {
  background-color: $color-white;
  color: $color-darkgreen;

  .BW & {
    color: $color-white !important;
    background-color: $color-black !important;

    @include respond-min($md) {
      &:hover {
        color: $color-white !important;
        background-color: $color-black !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.button.whiteblue {
  background-color: $color-white;
  color: $color-blue;

  .BW & {
    background-color: $color-white !important;
    color: $color-black !important;

    @include respond-min($md) {
      &:hover {
        background-color: $color-white !important;
        color: $color-black !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.button.whitedarkblue {
  background-color: $color-white;
  color: $color-green;

  .BW & {
    color: $color-white !important;
    background-color: $color-black !important;

    @include respond-min($md) {
      &:hover {
        color: $color-white !important;
        background-color: $color-black !important;
        text-decoration: underline;
      }
    }
  }

  @include respond-min($md) {
    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.menu-toggle {
  font-size: 2rem;
  position: absolute;
  top: 10px;
  right: 15px;
  color: #fff;
  background-color: $color-green;
  border-radius: 5px;
  border-radius: 5px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  padding: 0px;
  text-align: center;

  .BW & {
    background-color: $color-black;
  }

  .fa-times {
    display: none;
  }
  &.is-active {
    border: 1px solid $color-white;
  }
  &.is-active .fa-times {
    display: block;
  }
  &.is-active .fa-bars {
    display: none;
  }

  @include respond-min($md) {
    display: none;
  }
}

.sub-toggle {
  width: 20%;
  max-height: 40px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  .fa-caret-up {
    display: none;
  }
  &.is-active .fa-caret-up {
    display: block;
  }
  &.is-active .fa-caret-down {
    display: none;
  }

  @include respond-min($md) {
    display: none;
  }
}

/**
 * 2) Element
 */

.btn--primary {
}

.btn--secondary {
}

.btn--inverse {
}

.btn--ghost {
}

/**
 * 3) Modifier
 */

.btn--block {
  display: block;
}
