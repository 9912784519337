/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

.section-facts {

  p {
    font-weight: 400 !important;
  }

  .facts {
    @include respond-min($md) {
      float: right;
      clear: both;
      overflow: hidden;
    }

    .inner {
      .item {
        max-width: 260px;

        @include respond-max(480px) {
          margin: 0 auto;
          max-width: 100% !important;
          width: 50% !important;
        }

        @include respond-between(481px, 768px) {
          padding-left: 5px;
        }

        &:after, &:before {
          border-color: $color-primary !important;
        }

        h3 {
          color: $color-primary;
        }
        p {
          color: $color-darkgreen;
          font-weight: 400 !important;
        }
      }
    }
  }

  .image__item {
    float: none;
    clear: both;

    .image {
      padding-bottom: 100% !important;
      background-size: contain;
      background-position: bottom right !important;
      background-repeat: no-repeat;
      position: relative;
      display: block;
      border-radius: 20px;
    }
  }
  ul, .nav__sub {
    background-color: $color-white;
    border-radius: 10px;
    padding: 30px;
    display: block !important;
    position: relative !important;
    z-index: 0 !important;

    li {
      line-height: 22px;
      min-height: 22px;
      width: 100%;
      display: block;

      a {
        text-decoration: underline;
        color: $color-primary;

        line-height: 22px;
        min-height: 22px;
        padding: 6px 0;
        display: block;

        .BW & {
          color: $color-black !important;

          @include respond-min($md) {
            &:hover {
              color: $color-black;
            }
          }
        }

        @include respond-min($md) {
          &:hover {
            color: $color-darkgreen;
            text-decoration: none;
          }
        }
      }
    }
  }

  p {
    font-weight: $font-bold;
  }

  h2 {
    font-family: $font-serif;
    font-size: 32px;
    font-weight: $font-serif-black;
  }

}

.facts {

  .inner {
    h2 {
      font-family: $font-serif;
      color: $color-white;
      font-size: 32px;
      font-weight: $font-serif-bold;
      margin-bottom: 50px;
    }

    .item {
      @include respond-min($sm) {
        flex-grow: 1;
        position: relative;
        padding-left: 40px;
      }

      @include respond-max(480px) {
        padding-bottom: 20px;
        border-bottom: 1px dotted rgba($color-white, .2);

        &:first-child {
          border-top: 1px dotted rgba($color-white, .2);
        }
      }

      @include respond-between($xs, $md) {
        padding-left: 30px;
      }

      &:before {
        content: "";
        border-right: 1px dotted $color-white;
        height: 100%;
        position: absolute;
        left: 0;

        @include respond-max(480px) {
          border-right: 0px dotted $color-white;
        }
      }

      &:last-child {
        &:after {
          content: "";
          border-left: 1px dotted $color-white;
          height: 100%;
          right: 0;
          top: 0;
          position: absolute;

          @include respond-max(480px) {
            border-left: 0px dotted $color-white;
          }
        }
      }

      h3 {
        font-size: 96px;
        line-height: 80px;
        font-weight: $font-serif-regular;
        display: block;
        color: $color-white;
        margin-bottom: 0px;
        width: 100%;

        @include respond-max($md) {
          font-size: 60px !important;
        }
      }
      p {
        margin-bottom: 0px;
        color: $color-secondary;
        font-size: 36px;
        display: block;
        text-transform: lowercase;
        width: 100%;

        @include respond-max($md) {
          font-size: 24px !important;
        }

        .BW & {
          color: lighten($color-text, 30%);
        }
      }
    }
  }

}

/**
 * 1) Block
 */

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
