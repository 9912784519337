/**
 * Config/mixins stylesheet
 *
 * Table of contents
 * 1) Default variables
 * 2) Respond (min)
 * 3) Respond (max)
 * 4) Respond (between)
 * 5) Old-ie
 * 6) PX to REM
 */



/**
 * 1) Default variables;
 */

$old-ie: false !default;
$mqs-up-to: false !default;



/**
 * 2) Respond (min)
 */

@mixin respond-min($width) {
    @if $old-ie != true {
        @if $mqs-up-to {
            @if $mqs-up-to >= $width {
                @content;
            }
        } @else {
            @media screen and (min-width: $width) {
                @content;
            }
        }
    }
}



/**
 * 3) Respond (max)
 */

@mixin respond-max($width) {
    @if $old-ie != true {
        @if $mqs-up-to {
            @if $mqs-up-to <= $width {
                @content;
            }
        } @else {
            @media screen and (max-width: $width) {
                @content;
            }
        }
    }
}



/**
 * 4) Respond (between)
 */

@mixin respond-between($min, $max) {
    @if $old-ie != true {
        @if $mqs-up-to {
            @if $mqs-up-to >= $min and $mqs-up-to <= $max {
                @content;
            }
        } @else {
            @media screen and (min-width: $min) and (max-width: $max) {
                @content;
            }
        }
    }
}



/**
 * 5) Old-ie
 */

@mixin old-ie {
    @if $old-ie {
        @content;
    }
}



/**
 * 6) PX to REM
 */

@mixin font-size($value) {
    font-size: ($value) + px !important;
    font-size: ($value / 16) + rem !important;
}
