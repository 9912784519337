/**
 * Interface/navigation stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.changedvideo {
  min-height: 200px;
  max-width: 450px;
  padding: 30px;
  font-size: 20px;
  color: #afafaf;
  background: #eee;
  display: flex;
  cursor: pointer;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  @include respond-max(768px) {
    display: block;
  }

  &:before {
    content: "\f167";
    font-family: "Font Awesome 6 Pro";
    font-weight: $font-bold;
    font-size: 61px;
    margin-right: 10px;

    @include respond-max(768px) {
      display: block;
    }
  }
}

.cookie {
  display: block;
  z-index: 2000;
  position: absolute;

  .cookie-control--inverse & {
    display: none;
  }

  .box {
    max-width: 820px;
    width: 100%;
    background: rgba($color-white, .9);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 30px;
    display: block;
    font-size: 14px;
    margin: 0 auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .2);
    z-index: 2;
    min-height: 100px;
  }

  &.top {
    .box {
      top: 0;
      bottom: auto !important
    }

  }

  &.center {
    .box {
      bottom: auto;
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      content: "";
      background-color: rgba($color-text, .7);
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      display: block;
      z-index: 0;
    }
  }

  .button {
    background: #19b358 !important;
    border: solid 1px #19b358;
    text-decoration: none;
    text-align: center;
    color: $color-white;
    display: inline-block;
    line-height: 35px !important;
    min-height: 35px !important;
    padding: 0 15px 0 15px !important;
    font-size: 14px !important;
    font-weight: $font-regular;
    transition: all .3s ease;
    cursor: pointer;
    margin-bottom: 5px !important;

    @include respond-min($md) {
      &:hover {
        transition: all .3s ease;
        background: #367b13 !important;
        color: #fff !important;
        border-color: #367b13 !important;
      }
    }
  }

  .button-link {
    background: none !important;
    border: solid 1px transparent;
    text-decoration: none;
    text-align: center;
    color: $color-text;
    display: inline-block;
    line-height: 35px !important;
    min-height: 35px !important;
    padding: 0 15px 0 15px !important;
    font-size: 14px !important;
    font-weight: $font-regular;
    transition: all .3s ease;
    cursor: pointer;
    margin-bottom: 5px !important;

    @include respond-min($md) {
      &:hover {
        background: none !important;
        border: solid 1px transparent;
        text-decoration: underline !important;
      }
    }
  }
}
