/**
 * Modules/logo stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.page, .colors {
  .logo__wrapper {
    position: absolute;
    top: -20px;
    padding: 35px 30px 45px 30px;
    left: 15px;
    border-radius: 0 0 15px 15px;

    @include respond-max(767px) {
      padding: 35px 15px 30px 15px !important;

      .logo {
        width: 140px;
        height: 80px;
      }
    }
  }
}

.logo {
  display: block;
  width: 160px;
  height: 90px;

  &.logo-zw {
    display: none;
  }

  @include respond-min($md) {
    display: block;
    width: 160px;
    height: 90px;
  }

}

.logo__wrapper {
  @include respond-min($md) {
    position: absolute;
    top: 250px;
    left: 0;

    background-color: $color-white;
    padding: 60px 30px;
    border-radius: 0 15px 15px 0;
    box-shadow: 0px 5px 10px rgba($color-black, 0.5);
  }
  @include respond-max(991px) {
    position: absolute;
    top: 0px;
    left: 15px;
    z-index: 100;

    background-color: $color-white;
    padding: 15px 20px 25px 20px;
    border-radius: 0 0 15px 15px;
    box-shadow: 0px 5px 10px rgba($color-black, 0.5);
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
