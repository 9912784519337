/**
 * Base/forms stylesheet
 *
 * Table of contents
 * 1) Content
 */



/**
 * 1) Content
 */

input,
select,
textarea {
    outline: none;
}
