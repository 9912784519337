/**
 * Webshop/buttons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */



/**
 * 1) Block
 */

.filter-toggle {
    cursor: pointer;
}



/**
 * 2) Element
 */



/**
 * 3) Modifier
 */
