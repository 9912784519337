/**
 * Layout/footer stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

.footer {
  background-color: $color-primary;
  background-image: url("/assets/dist/img/bg-footer.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  position: relative;

  @include respond-max(1200px) {
    background-image:none !important;
    &:before{
      display: none !important;
    }
  }

  .logos {
    display: inline-block;

    .logo {
      width: 160px;
      height: 42px;
      margin-bottom: 10px;
    }
  }

  .quotes {
    position: relative;
    top: -4px;
    display: inline-block;
    color: $color-secondary;
    border-left: dotted 1px white;
    padding-left: 13px;
    margin-left: 10px;

    p {
      color: $color-secondary;
      margin-bottom: 0px;
      font-size: 18px;
      line-height: 20px;
      font-family: $font-serif;
      font-weight: $font-serif-regular;

      @include respond-max(480px) {
        display: none;
      }

      .BW & {
        color: lighten($color-black, 40%);
      }
    }
  }

  .BW & {
    background-color: $color-black;
    .social {
      a {
        @include respond-min($md) {
          &:hover {
            color: darken($color-white, 40%);
          }
        }
      }
    }
    .contact {
      span {
        color: $color-white !important;
      }
    }
  }

  .image__footer {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    display: block;
    right: 38%;
    width: 210px;
    height: 287px;
    bottom: 0;
    z-index: 10;

    @include respond-between($md, $lg) {
      right: 37% !important;
    }

    @include respond-max(991px) {
      right: 30px !important;
    }

  }

  @include respond-max(991px) {
    background-size: 20%;

    .social {
      a {
        margin-right: 10px !important;
      }
    }

    .footer__nav {
      ul {
        .nav__item {
          min-height: 50px;
          line-height: 50px;
          width: 100%;
          border-bottom: 1px solid rgba($color-white, 0.1);

          .nav__link {
            min-height: 50px;
            line-height: 50px;
            border-right: 0px !important;
          }

          &:first-child {
            border-top: 1px solid rgba($color-white, 0.1);
          }
        }
      }
    }
  }

  .social {
    a {
      text-decoration: none;
      color: $color-white;
      font-size: 48px;
      margin-right: 30px;

      @include respond-min($md) {
        &:hover {
          color: $color-green;
        }
      }
    }
  }

  .footer__nav {
    ul {
      .nav__item {
        color: rgba($color-white, .5);
        display: inline-block;
        font-size: 14px;
        font-weight: $font-light;

        &:last-child {
          .nav__link {
            border-right: 0px solid rgba($color-white, 0.1);
          }
        }

        .nav__link {
          color: rgba($color-white, .5);
          text-decoration: none;
          font-weight: $font-light;
          border-right: 1px solid rgba($color-white, 0.1);

          @include respond-max(991px) {
            padding: 0px;
          }

          @include respond-min($md) {
            min-height: 20px;
            line-height: 20px;
          }

          @include respond-min($md) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

/**
 * 1) Block
 */

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
