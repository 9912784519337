/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

.photos__col {
  position: relative;
  background-color: $color-secondary;

  .BW & {
    background-color: rgba($color-white, 1);
  }

  .image {
    background-position: center;
    background-size: cover;
    display: block;
    padding-bottom: 75%;
    border-radius: 10px;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 27.5px 0;
    border-color: $color-bg-light transparent transparent;
    position: absolute;
    top: 0px;
    margin: 0 auto;
    z-index: 2;
    right: 0;
    left: 0;

    .BW & {
      border-color: #cdd0d1 transparent transparent !important;
    }

    @include respond-max($sm) {
      border-color: $color-bg transparent transparent !important;
    }
  }
}

/**
 * 1) Block
 */

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
